import postRequest from "util/APIHelperPost";
import { baseUrl } from "util/APIBaseUrl";

export const updateEmployeeContactInfo = async (userId, data) => {
  const {
    payload: { error },
  } = await postRequest(`${baseUrl}/employee/${userId}`, JSON.stringify(data), true, "PATCH");
  if (error) {
    throw new Error("Profile could not be updated");
  }
};

export const updateEmergencyContact = async (userId, data) => {
  const {
    payload: { error },
  } = await postRequest(`${baseUrl}/employees/${userId}/emergencyContact`, JSON.stringify(data), true, "PUT");
  if (error) {
    throw new Error("Profile could not be updated");
  }
};
