import * as Yup from "yup";

export default Yup.object().shape({
  jobTitle: Yup.string()
    .required("Title is required")
    .min(2, "Title must be at least 2 characters long")
    .max(50, "Title cannot be longer than 50 characters"),

  employmentType: Yup.string().required("Employment Type is required"),

  companyName: Yup.string()
    .required("Company is required")
    .min(2, "Company must be at least 2 characters long")
    .max(50, "Company cannot be longer than 50 characters"),

  location: Yup.string().required("Location is required"),

  startDate: Yup.date().required("Start Date is required").typeError("Invalid date format"),

  endDate: Yup.date()
    .nullable()
    .when("currentlyWorkingHere", (currentlyWorkingHere, schema) => {
      return currentlyWorkingHere === false
        ? schema.required("End Date is required").typeError("Invalid date format")
        : schema.nullable();
    }),
});
