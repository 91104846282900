import * as React from "react";

import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import Fade from "@mui/material/Fade";

import AlertIcon from "../../../assets/images/alert-circle.svg";
import TickIcon from "../../../assets/images/tick-mark.svg";

import "./Snackbar.css";

export const AlertType = {
  success: "success",
  error: "error",
};

export default function PositionedSnackbar({ message = "", type, open = "false" }) {
  const position = {
    vertical: "bottom",
    horizontal: "center",
  };

  const { vertical, horizontal } = position;

  return (
    <Box sx={{ minWidth: "278px", maxWidth: "480px" }}>
      {open && type ? (
        <Snackbar
          className="snackbar"
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          TransitionComponent={Fade}
          message={
            <div className="snackbar-div">
              {type !== "info" && <img src={type === "success" ? TickIcon : AlertIcon} alt="icon" />}
              <p className="snackbar-text">{message}</p>
            </div>
          }
          key={vertical + horizontal}
        />
      ) : null}
    </Box>
  );
}
