import React, { useState } from "react";

import { CallOutlined, ModeEditOutlined } from "@mui/icons-material";

import Button from "components/common/Button";
import CustomModal from "components/common/Modal";

import EditContactInfo from "./EditContactInfo";

function ContactInfo({ data, onUpdate, countryStates }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleEdit = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleSave = (updatedData) => {
    onUpdate(updatedData);
    setIsModalOpen(false);
  };

  const fullAddress = [data.addressLine1, data.zip, data.city, data.state, data.country].filter(Boolean).join(", ");
  return (
    <div className="contact-container">
      <div className="flex justify-between items-center mb-125">
        <h4 className="heading-h4-semibold m-0 flex items-center">
          <CallOutlined className="contact-icon" /> Contact Info
        </h4>
        <Button variant="outlined" size="medium" label="Edit" startIcon={<ModeEditOutlined />} onClick={handleEdit} />
      </div>
      <div className="flex flex-col gap-1">
        <div className="flex justify-between items-center">
          <h5 className="m-0 heading-h5-regular">Phone number</h5>
          <p className="contact-value para-body-m-semibold">{`${data.phoneNumber || "-"}`}</p>
        </div>
        <div className="flex justify-between items-center">
          <h5 className="m-0 heading-h5-regular">Edge email address</h5>
          <p className="contact-value para-body-m-semibold">{`${data.email || "-"}`}</p>
        </div>
        <div className="flex justify-between items-center">
          <h5 className="m-0 heading-h5-regular">Personal email address</h5>
          <p className="contact-value para-body-m-semibold">{`${data.personalEmail || "-"}`}</p>
        </div>
        <div className="flex justify-between items-center">
          <h5 className="m-0 heading-h5-regular">Residential address</h5>
          <p className="contact-value para-body-m-semibold">{`${fullAddress || "-"}`}</p>
        </div>
      </div>
      <CustomModal
        open={isModalOpen}
        title="Contact"
        sx={{ maxWidth: "652px !important", width: "652px !important", padding: "24px !important" }}
      >
        <EditContactInfo initialData={data} onSave={handleSave} onCancel={handleClose} countryStates={countryStates} />
      </CustomModal>
    </div>
  );
}

export default ContactInfo;
