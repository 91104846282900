import React from "react";

function HolidaysIcon() {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.666 7.19922V4.69922H14.9993V5.53255H13.3327V4.69922H6.66602V5.53255H4.99935V4.69922H3.33268V7.19922H16.666ZM16.666 8.86589H3.33268V17.1992H16.666V8.86589ZM14.9993 3.03255H16.666C17.5865 3.03255 18.3327 3.77874 18.3327 4.69922V17.1992C18.3327 18.1197 17.5865 18.8659 16.666 18.8659H3.33268C2.41221 18.8659 1.66602 18.1197 1.66602 17.1992V4.69922C1.66602 3.77874 2.41221 3.03255 3.33268 3.03255H4.99935V2.19922H6.66602V3.03255H13.3327V2.19922H14.9993V3.03255ZM7.49935 12.1992H5.83268V10.5326H7.49935V12.1992ZM10.8327 12.1992H9.16602V10.5326H10.8327V12.1992ZM14.166 12.1992H12.4993V10.5326H14.166V12.1992ZM7.49935 15.5326H5.83268V13.8659H7.49935V15.5326ZM10.8327 15.5326H9.16602V13.8659H10.8327V15.5326Z"
        fill="#FBFAFF"
      />
    </svg>
  );
}

export default HolidaysIcon;
