import React, { useState, useEffect } from "react";

import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PercentIcon from "@mui/icons-material/Percent";

import Button from "components/common/Button";
import Text from "components/common/Text";
import DatePicker from "components/common/DatePicker";
import TextCheckBox from "components/common/TextCheckBox";

import SalaryDetailsSchema from "validations/BambooHRTabs/SalaryDetailsSchema";

function AddEditSalaryDetails({ onSave, onCancel, initialData = {}, employeeStatus }) {
  const [applyDiscount, setApplyDiscount] = useState(initialData.applyDiscount || false);

  const methods = useForm({
    resolver: yupResolver(SalaryDetailsSchema),
    mode: "onChange",
    context: { employeeStatus },
    defaultValues: {
      costToCustomer: initialData.costToCustomer || "",
      monthlyGrossSalary: initialData.monthlyGrossSalary || "",
      totalDeductions: initialData.totalDeductions || "",
      applyDiscount: initialData.applyDiscount || false,
      percentageDiscount: initialData.percentageDiscount || "",
      flatDiscount: initialData.flatDiscount || "",
      discountStartDate: initialData.discountStartDate ? new Date(initialData.discountStartDate) : null,
      discountEndDate: initialData.discountEndDate ? new Date(initialData.discountEndDate) : null,
    },
  });

  const {
    watch,
    setValue,
    clearErrors,
    formState: { isValid },
  } = methods;

  const costToCustomer = watch("costToCustomer");
  const monthlyGrossSalary = watch("monthlyGrossSalary");
  const totalDeductions = watch("totalDeductions");
  const percentageDiscount = watch("percentageDiscount");
  const flatDiscount = watch("flatDiscount");

  useEffect(() => {
    if (costToCustomer && monthlyGrossSalary) {
      const platformFee = Number(costToCustomer) - Number(monthlyGrossSalary);
      setValue("platformFee", platformFee.toFixed(2));
    } else {
      setValue("platformFee", "");
    }

    if (costToCustomer && monthlyGrossSalary) {
      let monthlyNetSalary = Number(monthlyGrossSalary) - Number(totalDeductions || 0);
      if (applyDiscount) {
        if (percentageDiscount) {
          monthlyNetSalary -= (monthlyNetSalary * Number(percentageDiscount)) / 100;
        } else if (flatDiscount) {
          monthlyNetSalary -= Number(flatDiscount);
        }
      }
      setValue("monthlyNetSalary", monthlyNetSalary.toFixed(2));
    } else {
      setValue("monthlyNetSalary", "");
    }
  }, [costToCustomer, monthlyGrossSalary, totalDeductions, applyDiscount, percentageDiscount, flatDiscount, setValue]);

  useEffect(() => {
    if (percentageDiscount) {
      setValue("flatDiscount", "");
      clearErrors("flatDiscount");
    }
  }, [percentageDiscount, setValue, clearErrors]);

  useEffect(() => {
    if (flatDiscount) {
      setValue("percentageDiscount", "");
      clearErrors("percentageDiscount");
    }
  }, [flatDiscount, setValue, clearErrors]);

  const onSubmit = (data) => {
    onSave(data);
  };

  return (
    <FormProvider {...methods}>
      <form className="flex flex-col gap-1 mt-15" onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="form-row two-columns">
          <Text
            name="costToCustomer"
            label="Cost to customer"
            type="number"
            startIcon={<AttachMoneyIcon className="money-icon" />}
          />
          <Text
            name="monthlyGrossSalary"
            label="Gross pay"
            type="number"
            startIcon={<AttachMoneyIcon className="money-icon" />}
          />
        </div>
        <div className="form-row two-columns">
          <Text
            name="platformFee"
            label="Platform fee"
            disabled
            type="number"
            startIcon={<AttachMoneyIcon className="money-icon" />}
            readOnly
          />
          <Text
            name="totalDeductions"
            label="Deduction (optional)"
            type="number"
            startIcon={<AttachMoneyIcon className="money-icon" />}
          />
        </div>
        <div className="form-row two-columns">
          <TextCheckBox
            name="applyDiscount"
            text="Apply discount"
            onChange={(e) => {
              setApplyDiscount(e.target.checked);
              methods.setValue("applyDiscount", e.target.checked);
            }}
          />
        </div>

        {applyDiscount && (
          <div className="flex flex-col gap-1">
            <div className="form-row two-columns">
              <Text
                name="percentageDiscount"
                label="Percentage discount"
                type="number"
                endAdornment="%"
                disabled={!!flatDiscount}
                startIcon={<PercentIcon className="money-icon" />}
              />
              <Text
                name="flatDiscount"
                label="Flat discount"
                type="number"
                startAdornment="$"
                disabled={!!percentageDiscount}
                startIcon={<AttachMoneyIcon className="money-icon" />}
              />
            </div>
            <div className="form-row two-columns">
              <DatePicker name="discountStartDate" label="Discount starts from" />
              <DatePicker name="discountEndDate" label="Discount ends in" />
            </div>
          </div>
        )}

        <Text disabled name="monthlyNetSalary" label="Net pay" type="number" startAdornment="$" readOnly />

        <div className="form-actions">
          <Button variant="outlined" size="large" label="Cancel" onClick={onCancel} />
          <Button
            variant="contained"
            size="large"
            label="Save"
            disabled={!isValid}
            color={!isValid && "disabled"}
            type="submit"
          />
        </div>
      </form>
    </FormProvider>
  );
}

export default AddEditSalaryDetails;
