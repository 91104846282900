import React from "react";

import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";

import Button from "components/common/Button";

import "./SalaryDetails.scss";

function NoSalaryDetails({ handleModalOpen }) {
  return (
    <div className="flex flex-col justify-center items-center gap-1 px-0 py-1 text-center">
      <PaymentsOutlinedIcon className="no-salary-icon" />
      <p className="para-body-m-regular">This employee does not have salary details added yet.</p>
      <Button variant="outlined" size="small" label="Add Salary Details" onClick={handleModalOpen} />
    </div>
  );
}

export default NoSalaryDetails;
