import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import CustomModal from "components/common/Modal";
import PageLoader from "components/common/PageLoader";

import { getEmployeeSalary, updateEmployeeSalary } from "services/BambooHrTabs/salarydetails";

import EmployeeAssignmentBanner from "./EmployeeAssignmentBanner";
import AddEditSalaryDetails from "./AddEditSalaryDetails";
import NoSalaryDetails from "./NoSalaryDetails";
import SalarySummary from "./SalarySummary";
import PaySlips from "./Payslips";

import "./SalaryDetails.scss";

function SalaryDetails() {
  const [isLoading, setIsLoading] = useState(true);
  const [salaryData, setSalaryData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState(null);

  const getEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.getEmployeeDetails);
  const LoadingGetEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.loadingGET_EMPLOYEE_Details);

  const employeeID = getEmployeeDetails?.payload?.employeeId;

  const employeeStatus = getEmployeeDetails?.payload?.status;

  const fetchSalaryData = async () => {
    setIsLoading(true);
    try {
      const data = await getEmployeeSalary(employeeID);
      setSalaryData(data?.payload);
    } catch (err) {
      setError("Failed to fetch salary data");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSalaryData();
  }, [employeeID]);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleEdit = () => {
    setIsModalOpen(true);
  };

  const handleSave = async (updatedData) => {
    setIsLoading(true);
    try {
      await updateEmployeeSalary(employeeID, updatedData);
      await fetchSalaryData();
    } catch (err) {
      setError("Failed to update salary data");
      console.error(err);
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  if (isLoading) {
    return <PageLoader />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      {(LoadingGetEmployeeDetails || isLoading) && <PageLoader />}
      <CustomModal
        open={isModalOpen}
        title={salaryData ? "Edit Salary" : "Add Salary"}
        sx={{ maxWidth: "652px !important", width: "652px !important", padding: "24px !important" }}
      >
        <AddEditSalaryDetails
          onSave={handleSave}
          onCancel={handleCancel}
          initialData={salaryData || {}}
          employeeStatus={employeeStatus}
        />
      </CustomModal>
      <div className="bg-white">
        {!salaryData ? (
          <NoSalaryDetails handleModalOpen={handleModalOpen} />
        ) : (
          <>
            {employeeStatus !== "assigned" && <EmployeeAssignmentBanner />}
            <SalarySummary
              costToCustomer={salaryData.costToCustomer}
              monthlyGrossSalary={salaryData.monthlyGrossSalary}
              totalDeductions={salaryData.totalDeductions}
              monthlyNetSalary={salaryData.monthlyNetSalary}
              onEditSalary={handleEdit}
            />
            <PaySlips employeeID={employeeID} payslipData={salaryData?.payslipData} />
          </>
        )}
      </div>
    </>
  );
}

export default SalaryDetails;
