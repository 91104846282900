import { Popper, Paper, ClickAwayListener } from "@mui/material";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { useState } from "react";

function EditDeleteMenu({ setOpenModal, menuItem, isSourceEdge = false }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const source = isSourceEdge ? "Edge" : "";

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className="icon-wrapper">
      <MoreVertOutlinedIcon className="timeline-more-icon" onClick={handleClick} />
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        disablePortal={false}
        modifiers={[
          {
            name: "flip",
            enabled: true,
            options: {
              altBoundary: true,
              rootBoundary: "viewport",
              padding: 8,
            },
          },
        ]}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper elevation={3} className="paper-style">
            <p
              className="edit-menu-items"
              onClick={() => {
                setOpenModal({ show: true, mode: "Edit", data: menuItem, source });
                handleClose();
              }}
            >
              Edit
            </p>
            <p
              className="edit-menu-items color-text-action-danger"
              onClick={() => {
                setOpenModal({ show: true, mode: "Delete", data: menuItem, source });
                handleClose();
              }}
            >
              Delete
            </p>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  );
}

export default EditDeleteMenu;
