import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Divider } from "@mui/material";
import { Helmet } from "react-helmet";

import EmployeeAbout from "./About";
import ToolBar from "components/common/PageToolbar";
import DocumentsTab from "components/Widgets/BambooHRTabs/Documents";
import ExperienceTab from "components/Widgets/BambooHRTabs/Experience";
import EducationTab from "components/Widgets/BambooHRTabs/Education";
import PageLoader from "components/common/PageLoader";
import EmployeeProfileStrip from "components/Screens/Employee/EmployeeProfileStrip";
import { usePermissions } from "components/Hook";

import { GetEmployeeById } from "store/actions/EmployeePortal/GetEmployeeDetials";
import { EMPLOYEE_PROFILE_TABS } from "constants";

import "./EmployeeProfile.css";

function EmployeeProfile() {
  const { employeeId } = useParams();
  const profileInfoRef = useRef(null);
  const [currentTab, setCurrentTab] = useState(EMPLOYEE_PROFILE_TABS[0]?.title);
  const loadingAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.loadingAdditionalInfo);

  // constants
  const dispatch = useDispatch();

  // redux state
  const employeeDetails = useSelector((state) => state.GetEmployeeDetials.employeeDetails);
  const isLoadingEmployeeDetails = useSelector((state) => state.GetEmployeeDetials.isLoadingEmployeeDetails);
  // custom hook
  const { loggedInUser } = usePermissions();

  useEffect(() => {
    if (employeeId) {
      dispatch(
        GetEmployeeById({
          employeeId,
        })
      );
    } else {
      dispatch(
        GetEmployeeById({
          employeeId: loggedInUser?.userId,
        })
      );
    }
  }, [dispatch, employeeId, loggedInUser?.userId]);
  return (
    <>
      <Helmet>
        <title>Edge | Profile</title>
      </Helmet>
      {(loadingAdditionalInfo || isLoadingEmployeeDetails) && <PageLoader />}
      <div ref={profileInfoRef} className="profile-container">
        <div className="py-05 mt-05">
          <p className="heading-h2-semibold profile-heading">Profile</p>
        </div>
        <Divider />
        <div className="ep-profile-main flex flex-row justify-center mt-3">
          <div className="mr-15">
            <EmployeeProfileStrip employeeDetails={employeeDetails} />
          </div>
          <div className="employee-profile-tab-container">
            <ToolBar tabs={EMPLOYEE_PROFILE_TABS} currentTab={currentTab} setCurrentTab={setCurrentTab} />
            <div className="employee-profile-d22 mt-15">
              {currentTab === "About" && <EmployeeAbout />}
              {currentTab === "Education" && <EducationTab employeeId={employeeDetails?.employeeId} />}
              {currentTab === "Experience" && <ExperienceTab employeeId={employeeDetails?.employeeId} />}
              {currentTab === "Documents" && <DocumentsTab noPadding />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmployeeProfile;
