/* eslint-disable no-restricted-globals */

import * as yup from "yup";

const SalaryDetailsSchema = yup
  .object()
  .shape({
    costToCustomer: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .typeError("Cost to customer must be a number")
      .min(0, "Cost to customer cannot be negative"),
    monthlyGrossSalary: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .typeError("Gross pay must be a number")
      .min(0, "Gross pay cannot be negative"),
    totalDeductions: yup
      .number()
      .nullable()
      .transform((value) => (isNaN(value) || value === "" ? null : value))
      .typeError("Deduction must be a number")
      .min(0, "Deduction cannot be negative"),
    applyDiscount: yup.boolean(),
    percentageDiscount: yup
      .number()
      .nullable()
      .transform((value) => (isNaN(value) || value === "" ? null : value))
      .typeError("Percentage discount must be a number")
      .min(0, "Percentage discount cannot be negative")
      .max(100, "Percentage discount cannot exceed 100%"),
    flatDiscount: yup
      .number()
      .nullable()
      .transform((value) => (isNaN(value) || value === "" ? null : value))
      .typeError("Flat discount must be a number")
      .min(0, "Flat discount cannot be negative"),
    monthlyNetSalary: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : parseFloat(value)))
      .typeError("Net salary must be a number"),
    platformFee: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : parseFloat(value)))
      .typeError("Platform fee must be a number"),
    discountStartDate: yup.date().nullable(),
    discountEndDate: yup.date().nullable(),
  })
  .test("cost-to-customer-required", function (values) {
    if (this.options.context.employeeStatus === "assigned" && !values.costToCustomer) {
      return this.createError({
        path: "costToCustomer",
        message: "Cost to Customer cannot be empty since this employee is assigned to a customer",
      });
    }
    return true;
  })
  .test("gross-pay-required", function (values) {
    if (this.options.context.employeeStatus === "assigned" && !values.monthlyGrossSalary) {
      return this.createError({
        path: "monthlyGrossSalary",
        message: "Gross pay cannot be empty since this employee is assigned to a customer",
      });
    }
    return true;
  })
  .test("at-least-one-salary", "Either Cost to customer or Gross pay is required", function (values) {
    if (!values.costToCustomer && !values.monthlyGrossSalary) {
      return this.createError({ path: "costToCustomer", message: "Either Cost to customer or gross pay is required" });
    }
    return true;
  })
  .test("cost-to-customer-greater-than-pay", "Cost to customer must be greater than gross pay", function (values) {
    if (values.costToCustomer && values.monthlyGrossSalary && values.costToCustomer <= values.monthlyGrossSalary) {
      return this.createError({ path: "costToCustomer", message: "Cost to customer must be greater than gross pay" });
    }
    return true;
  })
  .test("gross-pay-less-than-cost", "Gross pay must be less than Cost to customer", function (values) {
    if (values.costToCustomer && values.monthlyGrossSalary && values.monthlyGrossSalary >= values.costToCustomer) {
      return this.createError({ path: "monthlyGrossSalary", message: "Gross pay must be less than cost to customer" });
    }
    return true;
  })
  .test("discount-validation", null, function (values) {
    if (values.applyDiscount) {
      if (values.percentageDiscount && values.flatDiscount) {
        return this.createError({
          path: "flatDiscount",
          message: "Only one type of discount (percentage or flat) can be applied",
        });
      }
      if (!values.percentageDiscount && !values.flatDiscount) {
        return this.createError({
          path: "percentageDiscount",
          message: "Either percentage or flat discount must be provided when applying a discount",
        });
      }
      if (!values.discountStartDate) {
        return this.createError({
          path: "discountStartDate",
          message: "Discount start date is required when applying a discount",
        });
      }
      if (!values.discountEndDate) {
        return this.createError({
          path: "discountEndDate",
          message: "Discount end date is required when applying a discount",
        });
      }
      if (values.discountStartDate && values.discountEndDate && values.discountEndDate <= values.discountStartDate) {
        return this.createError({
          path: "discountEndDate",
          message: "Discount end date must be after start date",
        });
      }
    }
    return true;
  });

export default SalaryDetailsSchema;
