import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  IconButton,
  Typography,
  Snackbar,
  Alert,
  List,
  ListItem,
  Grid,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  Tooltip,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

import { ResetDeleteUser } from "store/actions/Settings/DeleteUser";
import { EmployeeInfo } from "store/actions/EmployeeInfo";

import ConfirmationDialog from "./ChurnedConfimationModal";
import { useFlags } from "launchdarkly-react-client-sdk";

function Delete(props) {
  const { selectedId, companyName, handleDeleteRow, title, customerStatus, type, totalEmployees } = props;
  const dispatch = useDispatch();

  const flags = useFlags();

  // component state
  const [openDialog, setOpenDialog] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [action, setAction] = useState("pause");
  const [openChurnedDialog, setOpenChurnedDialog] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [employeeCount, setEmployeeCount] = useState(0);

  const deleteUser = useSelector((state) => state.DeleteUser.deleteUser);

  const disableCustomer = useSelector((state) => state.DisableCustomer.disableCustomer);
  const employeesInfo = useSelector((state) => state.EmployeeInfo.employeesInfo);
  const loadingEmployeesInfo = useSelector((state) => state.EmployeeInfo.loadingEmployeesInfo);

  const handleDelete = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setOpenAlert(false);
    dispatch(ResetDeleteUser({}));
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setOpenChurnedDialog(false);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleChange = (event) => {
    setAction(event.target.value);
  };

  const handleConfirmDelete = () => {
    if (action === "churned") {
      if (totalEmployees > 0) {
        setEmployeeCount(0);
        setEmployees([]);
        dispatch(
          EmployeeInfo({
            customerID: selectedId,
            dist: {},
            sortBy: {},
            page: 0,
            rowsPerPage: totalEmployees,
          })
        );
        setOpenDialog(false);
        setOpenChurnedDialog(true);
      } else {
        setOpenDialog(false);
        handleDeleteRow(selectedId, action);
      }
    } else {
      setOpenDialog(false);
      handleDeleteRow(selectedId, action);
      setOpenDialog(false);
    }
  };

  const handleConfirmChurn = () => {
    handleDeleteRow(selectedId, "churned");
    setOpenChurnedDialog(false);
  };

  useEffect(() => {
    setEmployees(employeesInfo?.rows);
    setEmployeeCount(employeesInfo?.rows?.length);
  }, [employeesInfo, selectedId]);

  const getErrorMessage = (error) => {
    const message = error?.msg || error?.description || "";
    const param = error?.param || "";
    return `${message} ${param}`.trim();
  };

  const getError = (errors) => {
    if (errors != null && errors != undefined) {
      return errors.map((error) => {
        return (
          <List
            sx={{
              listStyleType: "disc",
              paddingRight: "0px !important",
              pl: 3,
              "& .MuiListItem-root": {
                paddingRight: "0px",
                paddingLeft: "0px",
                paddingTop: "0px",
                paddingBottom: "0px",
              },
            }}
          >
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              <Typography variant="h6" color="secondary" fontSize="12px">
                {getErrorMessage(error)}
              </Typography>
            </ListItem>
          </List>
        );
      });
    }
    return null;
  };

  useEffect(() => {
    if (Object.keys(deleteUser).length && deleteUser?.ok) {
      setOpenDialog(false);
    } else if (deleteUser?.ok == false) {
      setOpenAlert(true);
    }
  }, [deleteUser]);

  useEffect(() => {
    if (Object.keys(disableCustomer).length && disableCustomer?.ok) {
      setOpenDialog(false);
    } else if (disableCustomer?.ok == false) {
      setOpenAlert(true);
    }
  }, [disableCustomer]);

  const getAlertContent = () => {
    if (deleteUser?.ok === false) {
      return getError(deleteUser?.payload?.error);
    }
    if (disableCustomer?.ok === false) {
      return getError(disableCustomer?.payload?.error);
    }
    return null;
  };

  return (
    <>
      <Tooltip title={flags.softDeletion ? "Modify" : "Setting"}>
        <IconButton size="small" sx={{ "&:hover": { backgroundColor: "white" } }} onClick={handleDelete}>
          {flags.softDeletion ? (
            <SettingsOutlinedIcon
              fontSize="small"
              id="settings-icon"
              color="muted"
              sx={{ "&:hover": { color: "warning.main" } }}
            />
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {type == "edit" ? (
                <SettingsOutlinedIcon
                  fontSize="small"
                  id="settings-icon"
                  color="muted"
                  sx={{ "&:hover": { color: "warning.main" } }}
                />
              ) : (
                <DeleteForeverRoundedIcon
                  id="delete"
                  fontSize="small"
                  color="muted"
                  sx={{ "&:hover": { color: "warning.main" } }}
                />
              )}
            </>
          )}
        </IconButton>
      </Tooltip>

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        sx={{
          "& .MuiPaper-root": {
            width: "600px",
          },
        }}
      >
        <DialogTitle>
          {flags.softDeletion ? `Modify  ${title == "Customer" ? "Customer" : "Associate"}` : `Manage ${title}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {title == "Customer" ? (
              <Grid item container direction="column" rowSpacing={1}>
                <Grid item>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={action}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value={
                          customerStatus == "disabled" || customerStatus == "new" || customerStatus == "churned"
                            ? "enable"
                            : "disable"
                        }
                        control={<Radio />}
                        label={
                          <Typography variant="info">
                            {customerStatus == "disabled" || customerStatus == "new" || customerStatus == "churned"
                              ? `Activate ${title} `
                              : `Deactivate ${title} `}
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {!flags.softDeletion && (
                  <Grid item>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={action}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="delete"
                          control={<Radio />}
                          label={<Typography variant="info">{`Delete ${title} Permanently`}</Typography>}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                )}
                {(customerStatus == "enabled" || customerStatus == "disabled") && (
                  <Grid item>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={action}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="churned"
                          control={<Radio />}
                          label={
                            <Typography variant="info">{`${flags.softDeletion ? "Delete/Mark as Churned" : "Mark Customer as Churned"}`}</Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            ) : (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {flags.softDeletion ? (
                  <Grid item>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={action}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="inactive"
                          control={<Radio />}
                          label={
                            <Typography variant="info">{`Mark ${title == "Customer" ? "Customer" : "Associate"} as Inactive`}</Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                ) : (
                  <Typography variant="h6Sidebar">{`Are you sure you want to delete ${title}?`}</Typography>
                )}
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container direction="row">
            <Grid item xs={7.5}>
              <Snackbar
                open={openAlert}
                autoHideDuration={2500}
                onClose={handleAlertClose}
                sx={{
                  "&.MuiSnackbar-root": {
                    position: "relative",
                    bottom: 9,
                    left: 0,
                  },
                }}
              >
                <Alert onClose={handleAlertClose} severity="error">
                  {getAlertContent()}
                </Alert>
              </Snackbar>
            </Grid>
            <Grid item xs={1.5}>
              <Button onClick={handleClose}>
                <Typography variant="h6Sidebar">Cancel</Typography>
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  width: "140px",
                  borderRadius: 2,
                }}
                onClick={handleConfirmDelete}
              >
                <Typography variant="h6Sidebar" color="white">
                  Confirm
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={openChurnedDialog}
        handleClose={handleDialogClose}
        handleConfirm={handleConfirmChurn}
        companyName={companyName}
        employeeCount={employeeCount}
        title="Mark Customer as Churned"
        employees={employees}
        loadingEmployeesInfo={loadingEmployeesInfo}
      />
    </>
  );
}

export default Delete;
