import React from "react";
import Button from "components/common/Button";
import ContactEmergencyOutlinedIcon from "@mui/icons-material/ContactEmergencyOutlined";

function NoEmergencyContact({ onAdd }) {
  return (
    <div className="flex flex-col justify-center items-center gap-1 px-0 py-1 text-center">
      <ContactEmergencyOutlinedIcon className="no-contact-icon" />
      <p className="para-body-m-regular">Employee has not added an emergency contact yet.</p>
      <Button variant="outlined" size="small" label="Add Emergency Contact" onClick={onAdd} />
    </div>
  );
}

export default NoEmergencyContact;
