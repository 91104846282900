import React from "react";
import { useNavigate } from "react-router-dom";

import CustomModal from "components/common/Modal";
import Button from "components/common/Button";

import DoneIcon from "@mui/icons-material/Done";

import "./EmployeeAddSuccessModal.scss";

function EmployeeAddSuccessModal({ name, open, addAnotherEmployee }) {
  const navigate = useNavigate();

  return (
    <CustomModal open={open} width="474px">
      <div>
        <div className="success-tick-icon">
          <DoneIcon />
        </div>

        <div className="heading-h2-semibold pt-1">Employee Added Successfully</div>
        <div>
          <span className="para-body-m-semibold employee-success-name">{name}</span>{" "}
          <span className="para-body-m-regular pt-025">
            has been successfully added as a new employee in your workforce and an email has been sent to them with more
            details.
          </span>
        </div>

        <div className="float-right flex gap-05 pt-20p ">
          <Button
            label="Go Back to Workforce"
            variant="outlined"
            onClick={() => {
              navigate("/main/workforce");
            }}
          />
          <Button label="Add Another" variant="contained" onClick={addAnotherEmployee} />
        </div>
      </div>
    </CustomModal>
  );
}

export default EmployeeAddSuccessModal;
