import * as Yup from "yup";

export default Yup.object().shape({
  firstName: Yup.string()
    .min(2, "First Name must be at least 2 characters long")
    .max(50, "First Name cannot be longer than 50 characters")
    .required("First Name is required"),

  lastName: Yup.string()
    .min(2, "Last Name must be at least 2 characters long")
    .max(50, "Last Name cannot be longer than 50 characters")
    .required("Last Name is required"),

  country: Yup.string().required("Country is required"),

  nationalID: Yup.string().required("National ID is required"),

  gender: Yup.string().required("Gender is required"),

  dateOfBirth: Yup.date().typeError("Invalid date format").required("Date is required"),

  employeeSuccessManager: Yup.string()
    .min(2, "Employee Success Manager must be at least 2 characters long")
    .required("Employee Success Manager is required"),

  jobTitle: Yup.string().min(2, "Job Title must be at least 2 characters long").required("Job Title is required"),
});
