import React from "react";

import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";

function NoPaySlipsFound() {
  return (
    <div className="flex flex-col items-center justify-center py-15">
      <ReceiptOutlinedIcon className="receipt-icon mb-05" />
      <p className="para-body-m-regular">No pay slips found</p>
    </div>
  );
}

export default NoPaySlipsFound;
