import React from "react";

import { ModeEditOutlined } from "@mui/icons-material";

import Button from "components/common/Button";

import "./SalaryDetails.scss";

function SalarySummary({ costToCustomer, monthlyGrossSalary, totalDeductions, monthlyNetSalary, onEditSalary }) {
  return (
    <div className="pb-15 pt-15 px-2 flex justify-between items-center salary-info-container">
      <div className="salary-info">
        <div className="flex flex-col justify-start">
          <h2 className="m-0 heading-h2-semibold">${costToCustomer?.toLocaleString()}</h2>
          <p className="para-body-s-regular">Cost to Customer</p>
        </div>
        <div className="flex flex-col justify-start">
          <h2 className="m-0 heading-h2-semibold">${monthlyGrossSalary?.toLocaleString()}</h2>
          <p className="para-body-s-regular">Monthly Gross Salary</p>
        </div>
        <div className="flex flex-col justify-start">
          <h2 className="m-0 heading-h2-semibold">${totalDeductions?.toLocaleString()}</h2>
          <p className="para-body-s-regular">Total Deductions</p>
        </div>
        <div className="flex flex-col justify-start">
          <h2 className="m-0 heading-h2-semibold">${monthlyNetSalary?.toLocaleString()}</h2>
          <p className="para-body-s-regular">Monthly Net Salary</p>
        </div>
      </div>
      <Button
        variant="outlined"
        size="large"
        label="Edit Salary"
        startIcon={<ModeEditOutlined />}
        onClick={onEditSalary}
      />
    </div>
  );
}

export default SalarySummary;
