import React from "react";
import { Checkbox } from "@mui/material";
import "./TextCheckBox.scss";

function TextCheckBox({ text, checked, onChange }) {
  const handleClick = () => {
    onChange({ target: { checked: !checked } });
  };

  return (
    <div className="flex flex-row items-center justify-start" onClick={handleClick} style={{ cursor: "pointer" }}>
      <Checkbox className="check-box" checked={checked} onChange={onChange} />
      <p className="para-body-l-regular">{text}</p>
    </div>
  );
}

export default TextCheckBox;
