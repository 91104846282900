import React from "react";
import PropTypes from "prop-types";
import { Menu as MuiMenu, MenuList as MuiMenuList, MenuItem as MuiMenuItem } from "@mui/material";

import "./Menu.scss";

function Menu({ children, maxHeight = false, autoWidth, open, onClose, variant, elevation }) {
  return (
    <MuiMenu
      open={open}
      onClose={onClose}
      PaperProps={{
        elevation,
        variant,
        sx: {
          maxHeight: maxHeight ? "180px" : "auto",
          width: autoWidth ? "auto" : "220px",
        },
      }}
    >
      {children}
    </MuiMenu>
  );
}

Menu.propTypes = {
  children: PropTypes.node,
  maxHeight: PropTypes.bool,
  autoWidth: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["elevation", "outlined"]),
  elevation: PropTypes.oneOf([
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
  ]),
};

Menu.defaultProps = {
  children: null,
  maxHeight: false,
  autoWidth: false,
  open: true,
  onClose: () => {},
  variant: "elevation",
  elevation: 8,
};

function MenuList({ children = null, autoWidth = false }) {
  return <MuiMenuList sx={{ width: autoWidth ? "auto" : "220px" }}>{children}</MuiMenuList>;
}

MenuList.propTypes = {
  children: PropTypes.element,
  autoWidth: PropTypes.bool,
};

MenuList.defaultProps = {
  children: null,
  autoWidth: false,
};

function MenuItem({
  value,
  onClick,
  isSelected = false,
  isSmallScreen = false,
  isDense = false,
  isDisGutters = false,
  hasDivider = false,
  isDisabled = false,
  leftSlot = null,
  rightSlot = null,
}) {
  function getSize() {
    return isSmallScreen ? "large-menu-list-item" : "small-menu-list-item";
  }

  return (
    <MuiMenuItem
      selected={isSelected}
      className={`menu-item ${getSize()} ${isDisabled && "disabled-menu-item"}`}
      onClick={onClick}
      disabled={isDisabled}
      dense={isDense}
      disableGutters={isDisGutters}
      divider={hasDivider}
    >
      {leftSlot && <span className="slot-span left-slot">{leftSlot}</span>}
      {value}
      {rightSlot && <span className="slot-span right-slot">{rightSlot}</span>}
    </MuiMenuItem>
  );
}

MenuItem.propTypes = {
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isSmallScreen: PropTypes.bool,
  isDense: PropTypes.bool,
  isSelected: PropTypes.bool,
  isDisGutters: PropTypes.bool,
  isDisabled: PropTypes.bool,
  hasDivider: PropTypes.bool,
  leftSlot: PropTypes.node,
  rightSlot: PropTypes.node,
};

MenuItem.defaultProps = {
  isSmallScreen: false,
  isDense: false,
  isDisGutters: false,
  isDisabled: false,
  isSelected: false,
  hasDivider: false,
  leftSlot: null,
  rightSlot: null,
};

export default Menu;
export { MenuItem, MenuList };
