import React from "react";
import { Divider } from "@mui/material";

import "./time-line.scss";

function Timeline({ timelineData }) {
  return (
    <div className="timeline">
      {timelineData?.map((item) => (
        <div key={item?.id} className="timeline-item">
          <div className="timeline-dot" />
          <div className="timeline-content">
            <div className={`timeline-header ${item.icon ? "with-icon" : "without-icon"}`}>
              <h3 className="timeline-title">{item.title}</h3>
              <Divider flexItem className="timeline-divider" />
              <div className="timeline-date">
                {item.date && <span>{item.date}</span>}
                {item.date && item.duration && " • "}
                {item.duration && <span>{item.duration}</span>}
              </div>
              <div className="time-line-icon">{item.icon}</div>
            </div>
            <p className="timeline-company">{item.company}</p>
            <ul className="timeline-details">
              {item.description?.map((detail) => (
                <li key={detail}>{detail}</li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Timeline;
