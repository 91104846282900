import postRequest from "util/APIHelperPost";
import getRequest from "util/APIHelperGet";
import { baseUrl } from "util/APIBaseUrl";
import { formatDate } from "util/utils";

export const updateEmployeeSalary = async (employeeId, data) => {
  try {
    const response = await postRequest(
      `${baseUrl}/v2/employees/${employeeId}/salary`,
      JSON.stringify({
        costToCustomer: data.costToCustomer,
        grossSalary: data.monthlyGrossSalary,
        netSalary: data.monthlyNetSalary,
        platformFee: data.platformFee,
        deduction: data.totalDeductions,
        discountApplied: data.applyDiscount,
        discountPercentage: data.percentageDiscount,
        flatDiscount: data.flatDiscount,
        discountStartDate: formatDate(data.discountStartDate),
        discountEndDate: formatDate(data.discountEndDate),
      }),
      true,
      "PUT"
    );
    return response;
  } catch (error) {
    console.error("Failed to update employee salary:", error);
    throw error;
  }
};

export const getEmployeeSalary = async (employeeId) => {
  try {
    const response = await getRequest(`${baseUrl}/v2/employees/${employeeId}/salary`, true);
    return response;
  } catch (error) {
    console.error("Failed to fetch employee salary:", error);
    throw error;
  }
};
