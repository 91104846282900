import { baseUrl } from "util/APIBaseUrl";
import getRequest from "util/APIHelperGet";
import postRequest from "util/APIHelperPost";
import deleteRequest from "util/ApiHelperDelete";

import { AlertType } from "components/common/Snackbar";
import { showSnackbar } from "store/actions/Utility";

export const getEmployeeEducationById = async (employeeId) => {
  try {
    const response = await getRequest(`${baseUrl}/employee/${employeeId}/education`);
    return response;
  } catch (error) {
    console.error("Failed to get getEmployeeEducationById:", error);
    throw error;
  }
};

export const postEmployeeEducation = async (employeeId, data, dispatch) => {
  try {
    const response = await postRequest(`${baseUrl}/employee/${employeeId}/education`, JSON.stringify(data));
    dispatch(showSnackbar({ type: AlertType.success, message: "Education Added Successfully", dispatch }));
    return response;
  } catch (error) {
    dispatch(
      showSnackbar({ type: AlertType.error, message: error?.message || "Failed to add employee education", dispatch })
    );
    throw error;
  }
};

export const editEmployeeEducation = async (employeeId, data, educationId, dispatch) => {
  try {
    const response = await postRequest(
      `${baseUrl}/employee/${employeeId}/education/${educationId}`,
      JSON.stringify(data),
      false,
      "PUT"
    );
    dispatch(showSnackbar({ type: AlertType.success, message: "Education Edited Successfully", dispatch }));
    return response;
  } catch (error) {
    dispatch(
      showSnackbar({ type: AlertType.error, message: error?.message || "Failed to edit employee education", dispatch })
    );
    throw error;
  }
};

export const deleteEmployeeEducation = async (employeeId, educationId, dispatch) => {
  try {
    const response = await deleteRequest(`${baseUrl}/employee/${employeeId}/education/${educationId}`);
    dispatch(showSnackbar({ type: AlertType.success, message: "Experience Deleted Successfully", dispatch }));
    return response;
  } catch (error) {
    dispatch(
      showSnackbar({ type: AlertType.error, message: error?.message || "Failed to delete education", dispatch })
    );
    throw error;
  }
};
