import React from "react";

import { convertUTCTimeIntoLocalUserTime } from "components/Screens/CommonUtils";

import LastLoginIcon from "assets/images/login-icon.svg";

import "./LastActive.css";

function LastActive({ lastActiveEmail: email, lastActive: lastActiveTime = "" }) {
  return (
    <div className="last-login-container">
      <img className="align-self-start last-login-icon" src={LastLoginIcon} alt="login" />

      <span className="last-login-title para-body-s-regular">Last Active:</span>

      <div className="flex flex-col">
        {email && <span className="para-body-s-medium last-login-email align-self-start">{email}</span>}
        <span className="para-body-s-medium last-login-data align-self-start">
          {convertUTCTimeIntoLocalUserTime(lastActiveTime)}
        </span>
      </div>
    </div>
  );
}

export default LastActive;
