import React from "react";

import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";

import "./document.scss";

function Document({ title, primaryInfo, secondaryInfo, showOptions }) {
  return (
    <div className="folder-wrapper space-y-1">
      <div className="folder-container">
        {showOptions && <MoreVertOutlinedIcon className="more-icon" />}
        <FolderOutlinedIcon className="folder-icon" />
      </div>
      <div className="flex flex-col">
        <p className="heading-h5-medium folder-title">{title}</p>
        <div className="para-body-s-regular">
          <p className="primary-info para-body-s-regular">{primaryInfo}</p>
          <p className="secondary-info">{secondaryInfo}</p>
        </div>
      </div>
    </div>
  );
}

export default Document;
