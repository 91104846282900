import React, { createRef, useState } from "react";
import { useSelector } from "react-redux";

import { Avatar } from "@mui/material";

import { getValue } from "components/Screens/CommonUtils";
import PageLoader from "components/common/PageLoader";
import PersonalInfo from "components/Screens/Workforce/UserDetails/NewPersonalInfo/PersonalInfo";
import LastActive from "components/common/LastActive";
import EmploymentInformation from "components/Screens/Workforce/UserDetails/NewPersonalInfo/EmploymentInfo";
import EditPersonalInfoModal from "components/Screens/Workforce/UserDetails/NewPersonalInfo/EditPersonalInfoModal";
import ImageCropperModal from "components/common/ImageCropperModal";

import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";

import { baseUrl } from "util/APIBaseUrl";

import { uploadWorkforcePhoto } from "services/Admin/Dashboard";

import "./PersonalInfo.scss";

function PersonalInformationTalent() {
  const getEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.getEmployeeDetails);
  const employeeDetailsLoading = useSelector((state) => state.GetEmployeeDetails.loadingGET_EMPLOYEE_Details);

  const [showImageCropperModal, setShowImageCropperModal] = useState(false);
  const [showEditPersonalInfoModal, setShowEditPersonalInfoModal] = useState(false);
  const [imageSrc, setImageSrc] = useState();
  const [croppedUserImage, setCroppedUserImage] = useState();

  const [isFileUploadError, setIsFileUploadError] = useState(false);

  const photoInput = createRef();

  const uploadProfilePic = () => {
    photoInput.current.click();
  };

  const {
    lastActive,
    lastActiveEmail,
    employeeId,
    firstName,
    middleName,
    lastName,
    employeeSuccessManager,
    country,
    cnic_dni_cex: nationalID,
    jobTitle,
    gender,
    dateOfBirth,
  } = getEmployeeDetails?.payload || {};

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.addEventListener(
        "load",
        () => {
          resolve(reader.result);
        },
        false
      );
      reader.readAsDataURL(file);
    });
  }

  const handleImageChange = async (e) => {
    e.preventDefault();

    const file = e.target.files[0];
    e.target.value = "";

    if (file.size / 1024 / 1024 > 2) setIsFileUploadError(true);
    else setIsFileUploadError(false);

    const imageDataUrl = await readFile(file);
    setImageSrc(imageDataUrl);

    setShowImageCropperModal(true);
  };

  const handleSave = async (param) => {
    const formData = new FormData();
    const imageDataUrl = await readFile(param);
    setCroppedUserImage(imageDataUrl);

    formData?.append("file", param);
    setShowImageCropperModal(false);
    uploadWorkforcePhoto({
      request: `/employee/${employeeId}/logo`,
      formData,
    });
  };

  return (
    <div>
      {employeeDetailsLoading && <PageLoader />}

      <EditPersonalInfoModal
        open={showEditPersonalInfoModal}
        closeModal={() => setShowEditPersonalInfoModal(false)}
        initialPersonalDataWorkforce={{
          employeeId,
          firstName,
          middleName,
          lastName,
          employeeSuccessManager,
          country,
          jobTitle,
          nationalID,
          gender,
          dateOfBirth,
        }}
      />

      <ImageCropperModal
        open={showImageCropperModal}
        onClose={() => setShowImageCropperModal(false)}
        imageSrc={imageSrc}
        onSave={handleSave}
        isFileUploadError={isFileUploadError}
        errorMessage="The file you've selected is too large. Please upload an image file file that is 2MB or smaller."
      />
      <div className="workforce-overview-container">
        <div className="employee-info-wrapper">
          <div className="personal-info-container">
            <PersonalInfo showEditPersonalInfoModal={() => setShowEditPersonalInfoModal(true)} />
          </div>

          <div>
            <EmploymentInformation
              initialPersonalDataEmployment={{
                employeeId,
              }}
            />
          </div>
        </div>

        <div className="avatar-container">
          <div className="avatar-wrapper">
            <div className="relative">
              <Avatar
                variant="rounded"
                className="employee-overview-avatar"
                imgProps={{ crossOrigin: "anonymous" }}
                src={
                  croppedUserImage ??
                  `${baseUrl}/employee/${getEmployeeDetails?.payload?.employeeId}/logo?id=${getEmployeeDetails?.payload?.pictureVersion}`
                }
              />

              <div className="camera-image-upload-icon">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  ref={photoInput}
                  style={{ display: "none" }}
                />
                <CameraAltOutlinedIcon className="color-icon-primary" onClick={uploadProfilePic} />
              </div>
            </div>

            <div className="heading-h2-semibold text-center avatar-emp-name">
              {`${getValue(getEmployeeDetails.payload?.firstName)} ${getValue(getEmployeeDetails.payload?.lastName)}`}
            </div>

            <LastActive {...{ lastActive, lastActiveEmail }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalInformationTalent;
