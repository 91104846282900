import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";

import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Box, Stack } from "@mui/material";

import AppHeader from "./navbar.component";
import AppSideBar from "./sidebar.component";
import PageLoader from "components/common/PageLoader";

import CrossButtonIcon from "assets/svgs/CrossButtonIcon";
import BubbleTipIcon from "assets/svgs/BubbleTipIcon";
import avatarImage from "assets/images/fallback-image.svg";

import { usePermissions } from "components/Hook";
import { getProfileImage, isEmptyObject } from "components/Screens/CommonUtils";
import { checkIfPadding24Needed, getBackgroundColor } from "util/utils";

import { GetNoticeBoard } from "store/actions/DashBoard/GetNoticeBoard";

import "./main.component.css";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  minHeight: "46px !important",
}));

export default function MiniDrawer() {
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(true);
  const [openBubble, setOpenBubble] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null); // anchor the menu
  const location = useLocation();

  const [imageSrc, setImageSrc] = React.useState(avatarImage);
  const [closeSideBarFromMenuIcon, setCloseSideBarFromMenuIcon] = React.useState(false);

  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);
  const loadingAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.loadingAdditionalInfo);

  const { loggedInUser, loggedInUserType } = usePermissions();

  const handleBubble = () => {
    setOpenBubble(!openBubble);
  };

  const copyToClipboard = (CopiedText) => {
    navigator.clipboard.writeText(CopiedText);
  };

  React.useEffect(() => {
    if (userAdditionalInfo?.accountManager?.picturePath) {
      getProfileImage(
        userAdditionalInfo?.accountManager?.userId,
        userAdditionalInfo?.accountManager?.pictureVersion,
        "user"
      ).then((img) => {
        setImageSrc(img);
      });
    } else {
      setImageSrc(avatarImage);
    }
  }, [userAdditionalInfo]);

  React.useEffect(() => {
    if (isEmptyObject(loggedInUser) && loggedInUserType !== "employee") {
      const req = {
        edge: "customers/noticeboard",
        customer: `customer/${loggedInUser?.customerId}/noticeboard`,
      };

      dispatch(
        GetNoticeBoard({
          request: req[loggedInUserType],
        })
      );
    }
  }, [loggedInUser, loggedInUserType]);

  React.useEffect(() => {
    if (window.innerWidth < 1280) setOpen(false);
  }, []);

  React.useEffect(() => {
    if (location?.pathname === "/main/community") setOpen(false);
    if (window.innerWidth > 1280 && location?.pathname !== "/main/community" && open == false)
      if (closeSideBarFromMenuIcon !== true) setOpen(true);
      else setOpen(false);
  }, [location]);

  return (
    <>
      {loadingAdditionalInfo && <PageLoader />}
      <div style={{ display: "flex", overflowX: "hidden", width: "100%" }} className="disable-scrollbars">
        <CssBaseline />

        <AppHeader
          open={open}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          setOpen={setOpen}
          userDetails={loggedInUser}
          userAdditionalInfo={userAdditionalInfo}
          setCloseSideBarFromMenuIcon={setCloseSideBarFromMenuIcon}
        />

        <AppSideBar open={open} setOpen={setOpen} userDetails={loggedInUser} userAdditionalInfo={userAdditionalInfo} />
        <Box
          component="main"
          className="main-box"
          sx={{
            flexGrow: 1,
            p: loggedInUserType === "edge" && checkIfPadding24Needed() ? "24px !important" : "0px !important",
            backgroundColor: getBackgroundColor(),
          }}
        >
          {location?.pathname !== "/main/community" ? <DrawerHeader /> : null}
          {loggedInUserType === "edge" ? (
            <Stack
              sx={{
                maxHeight: "calc(100vh - 95px)",

                overflow: "auto",
              }}
            >
              <Outlet />
            </Stack>
          ) : (
            <Outlet />
          )}
          {/* <Outlet /> */}
          {loggedInUserType === "customer" && (
            <Box className="bubble-popup">
              <Box
                onClick={handleBubble}
                component="img"
                // src={`../assets/Images/Bubble-Image.png`}
                src={
                  imageSrc[userAdditionalInfo?.accountManager?.userId]
                    ? imageSrc[userAdditionalInfo?.accountManager?.userId]
                    : avatarImage
                }
                width="70px"
                height="70px"
                sx={{ width: "70px", height: "70px" }}
              />
              {!openBubble && (
                <Box className="bubble-content">
                  {userAdditionalInfo?.accountManager?.userId ? (
                    <>
                      <p className="hand-wave">👋</p>
                      <div className="cross-button" onClick={handleBubble}>
                        <CrossButtonIcon />
                      </div>
                      <p>
                        Hello, I am <strong>{userAdditionalInfo?.accountManager?.firstName} !</strong> your new Account
                        Manager. Contact me in case you need any help!
                      </p>
                      <div className="contact">
                        <span>Email</span>
                        <div>
                          <p>{userAdditionalInfo?.accountManager?.email}</p>
                          <button
                            type="button"
                            onClick={() => copyToClipboard(userAdditionalInfo?.accountManager?.email)}
                          >
                            Copy
                          </button>
                        </div>
                      </div>
                      <div className="contact">
                        <span>Phone number</span>
                        <div>
                          <p>{userAdditionalInfo?.accountManager?.phoneNumber}</p>
                          <button
                            type="button"
                            onClick={() => copyToClipboard(userAdditionalInfo?.accountManager?.phoneNumber)}
                          >
                            Copy
                          </button>
                        </div>
                      </div>
                      <div className="tip-down">
                        <BubbleTipIcon />
                      </div>
                    </>
                  ) : (
                    <p style={{ marginBottom: "0" }}>Hi! You will be assigned with your account manager shortly.</p>
                  )}
                </Box>
              )}
            </Box>
          )}
        </Box>
      </div>
    </>
  );
}
