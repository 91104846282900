import React, { useCallback, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Avatar, Box, CardMedia, FormControl, Grid, Typography } from "@mui/material";

import {
  convertDate,
  getCountryFlag,
  getFieldValue,
  getMonthFromDate,
  getProfileImage,
  getValue,
  isValidResponse,
} from "components/Screens/CommonUtils";
import PageLoader from "components/common/PageLoader";
import AlertError from "components/Widgets/AlertError";
import { usePermissions } from "components/Hook";
import TextFieldV2 from "components/common/TextFieldV2/TextFieldV2";
import Button from "components/Button/Button";

import { GiveRaise, ResetGiveRaise } from "store/actions/Raises/GiveRaise";
import { UserAdditionalInfo } from "store/actions/UserAdditionalInfo";
import { GetEmployeeDetails } from "store/actions/GetEmployeeDetails";

import avatarImage from "assets/images/fallback-image.svg";
import CreditCardIcon from "assets/svgs/CreditCardIcon";
import GiftIcon from "assets/svgs/GiftIcon";

const initialSalryDetials = {
  bonus: "",
  perIncrease: "",
  salary: "",
  raiseSalaryAmount: "",
};

const fallbackImage = avatarImage;

function EditSalary(props) {
  const { setTab, costToCompany } = props;
  const dispatch = useDispatch();

  const { loggedInUser, loggedInUserType } = usePermissions();

  const getEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.getEmployeeDetails);
  const loadingGetEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.loadingGET_EMPLOYEE_Details);

  const salaryInfo = useSelector((state) => state.SalaryInfo);
  const loadingEmployeeSalaryInfo = useSelector((state) => state.SalaryInfo.loadingEmployeeSalaryInfo);

  const giveRaiseInfo = useSelector((state) => state.GiveRaise.giveRaiseInfo);
  const loadingGiveRaiseInfo = useSelector((state) => state.GiveRaise.loadingGiveRaiseInfo);

  const [salaryDetails, setSalaryDetails] = useState(initialSalryDetials);
  const [imageSrc, setImageSrc] = useState(avatarImage);
  const [openAlert, setOpenAlert] = useState(false);

  const getPerIncrease = (salaryInput, raiseInSalary) => {
    let percentageIncrease = "";
    if (salaryInput > parseInt(costToCompany, 10) && costToCompany) {
      percentageIncrease = (raiseInSalary / parseInt(costToCompany, 10)) * 100;
    } else {
      percentageIncrease = 0;
    }
    percentageIncrease = parseFloat(percentageIncrease).toFixed(1);
    return percentageIncrease;
  };

  const getSalaryIncrease = (Percentage) => {
    let raiseInSalary = 0;
    let raiseSalaryAmount = 0;

    if (Percentage != "") {
      raiseInSalary = parseInt(costToCompany, 10) + (parseInt(costToCompany, 10) * parseInt(Percentage, 10)) / 100;
      raiseInSalary = parseInt(raiseInSalary, 10);
      raiseSalaryAmount = raiseInSalary - parseInt(costToCompany, 10);
    } else {
      raiseInSalary = parseInt(costToCompany, 10);
      raiseSalaryAmount = 0;
    }

    return { raiseInSalary, raiseSalaryAmount };
  };

  const getImageSrc = () => {
    if (isValidResponse(getEmployeeDetails)) {
      const employeeId = getEmployeeDetails?.payload?.employeeId;
      return imageSrc[employeeId] || fallbackImage;
    }
    return fallbackImage;
  };

  const handleInputChange = (event) => {
    const input = event.target.value.replace(/[^0-9]/g, "");
    if (event.target.name === "salary") {
      const salaryInput = input;

      const raiseInSalary = salaryInput - parseInt(costToCompany, 10);

      const percentageIncrease = getPerIncrease(salaryInput, raiseInSalary);

      setSalaryDetails({
        ...salaryDetails,
        [event.target.name]: salaryInput,
        perIncrease: percentageIncrease,
        raiseSalaryAmount: raiseInSalary,
      });
    } else if (event.target.name === "perIncrease") {
      const PerInput = input;

      const raise = getSalaryIncrease(PerInput);
      setSalaryDetails({
        ...salaryDetails,
        [event.target.name]: PerInput,
        salary: raise.raiseInSalary,
        raiseSalaryAmount: raise.raiseSalaryAmount,
      });
    } else {
      setSalaryDetails({
        ...salaryDetails,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleSave = () => {
    const requestPayload = {
      employeeId: getEmployeeDetails?.payload?.employeeId,

      amount: parseInt(salaryDetails?.salary, 10) + parseInt(getFieldValue(salaryDetails?.bonus), 10),

      bonusMonth: new Date(),
      incrementAmount: salaryDetails?.raiseSalaryAmount,
      bonus: salaryDetails?.bonus,
      incrementPercentage: salaryDetails?.perIncrease,
    };

    if (salaryDetails?.raiseSalaryAmount >= 0) {
      setOpenAlert(false);

      dispatch(
        GiveRaise({
          request: "addbonus",
          employeesIncreamentedSalary: [requestPayload],
        })
      );
      setTab("Overview");
    } else {
      setOpenAlert(true);
    }
  };

  const getProfilePicture = useCallback(() => {
    if (getEmployeeDetails?.payload.picturePath) {
      getProfileImage(getEmployeeDetails?.payload.employeeId, getEmployeeDetails?.payload?.pictureVersion).then(
        (img) => {
          setImageSrc(img);
        }
      );
    } else {
      setImageSrc(avatarImage);
    }
  }, [getEmployeeDetails]);

  const setSalaryInfo = useCallback(() => {
    setSalaryDetails({
      ...salaryDetails,
      bonus: getValue(getEmployeeDetails?.payload?.incrementDetails?.bonus),
      perIncrease: getFieldValue(getEmployeeDetails?.payload?.incrementDetails?.incrementPercentage),
      salary: getFieldValue(
        costToCompany + getFieldValue(getEmployeeDetails?.payload.incrementDetails.incrementAmount)
      ),

      raiseSalaryAmount: getFieldValue(getEmployeeDetails?.payload.incrementDetails?.incrementAmount),
    });
  }, [getEmployeeDetails]);

  useEffect(() => {
    let request = null;
    if (giveRaiseInfo?.ok == false) {
      setOpenAlert(true);
    } else if (giveRaiseInfo?.ok) {
      setOpenAlert(false);
      request = `customer/${loggedInUser?.customerId}/info`;
      const empDetailReq = {
        customer: `customer/${loggedInUser?.customerId}/employee/${getEmployeeDetails?.payload?.employeeId}`,
      };
      dispatch(UserAdditionalInfo(request));
      dispatch(
        GetEmployeeDetails({
          request: empDetailReq[loggedInUserType],
        })
      );
      dispatch(ResetGiveRaise({}));
    }
  }, [giveRaiseInfo]);

  useEffect(() => {
    if (isValidResponse(getEmployeeDetails)) {
      setSalaryInfo();
      getProfilePicture();
    }
  }, [getEmployeeDetails]);

  return (
    <>
      {loadingGetEmployeeDetails || loadingEmployeeSalaryInfo || loadingGiveRaiseInfo ? <PageLoader /> : null}
      <Grid
        container
        direction="column"
        rowSpacing={3}
        sx={{
          backgroundColor: "#FBFAFF",
          minHeight: "calc(100vh - 51px)",
        }}
      >
        <Grid
          sx={{
            maxWidth: "894px",
            width: "100%",
            margin: "0px auto",
            backgroundColor: "#FFFFFF",
            borderRadius: "24px",
            boxShadow: "0px 1px 1px rgba(2, 0, 110, 0.22), 0px 1px 6px rgba(2, 0, 110, 0.25)",
            marginTop: "60px",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "22px",
            }}
          >
            <Grid sx={{ display: "flex", alignItems: "center", gap: "28px" }}>
              <Box
                sx={{
                  borderRadius: "10px",
                  backgroundColor: "#E0E0FF",
                  width: "116px",
                  position: "relative",
                }}
              >
                <CardMedia
                  component="img"
                  image={getImageSrc()}
                  alt={getEmployeeDetails.payload?.firstName}
                  sx={{
                    width: "116px",
                    height: "116px",
                    borderRadius: "12px",
                  }}
                />
                <Avatar
                  sx={{
                    width: "26px",
                    height: "26px",
                    background: "#FFFFFF",
                    borderRadius: "99px",
                    position: "absolute",
                    bottom: "4px",
                    left: "4px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {getCountryFlag(getEmployeeDetails?.payload?.country)}
                </Avatar>
              </Box>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "6px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins-Medium",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#212231",
                  }}
                >
                  {`${getValue(getEmployeeDetails.payload?.firstName)} ${getValue(getEmployeeDetails.payload?.lastName)}`}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins-Regular",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "16px",
                    color: "#7C7EB6",
                  }}
                >
                  {getValue(getEmployeeDetails?.payload?.jobTitle)}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins-Regular",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "16px",
                    color: "#212231",
                  }}
                >
                  Join Date: {convertDate(getEmployeeDetails?.payload?.edgeJoiningDate)}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                gap: "4px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins-SemiBold",
                  fontWeight: 600,
                  fontSize: "22px",
                  lineHeight: "28px",
                  color: "#212231",
                }}
              >
                ${" "}
                {getFieldValue(costToCompany) +
                  getFieldValue(salaryDetails?.raiseSalaryAmount) +
                  parseInt(getFieldValue(salaryDetails?.bonus), 10)}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins-Regular",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: "#303038",
                }}
              >
                Total Salary for {getMonthFromDate(salaryInfo?.employeeSalaryInfo?.payload?.paymentDate)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              padding: "16px 20px",
              backgroundColor: "#FAFAFF",
            }}
          >
            <Typography
              sx={{
                maxWidth: "730px",
                fontFamily: "Poppins-Regular",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "16px",
                color: "#212231",
              }}
            >
              You are giving the employee a raise. This raise will be applicable for the month of for the month of{" "}
              {getMonthFromDate(salaryInfo?.employeeSalaryInfo?.payload?.paymentDate)} and all subsequent months until
              you edit the salary again. However, the bonus will only be applicable for the current month.
            </Typography>
            {/* <CrossIcon /> */}
          </Grid>
          <Grid
            sx={{
              padding: "32px 22px",
              display: "flex",
              flexDirection: "column",
              gap: "38px",
            }}
          >
            <Grid sx={{ display: "flex", alignItems: "center", gap: "40px" }}>
              <Typography
                sx={{
                  fontFamily: "Poppins-Regular",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "20px",
                  color: "#212231",
                  width: "200px",
                }}
              >
                Enter New Salary
              </Typography>
              <FormControl sx={{ position: "relative" }} variant="outlined" error>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #B2B4E2",
                    borderRadius: "12px",
                    maxWidth: "318px",
                  }}
                >
                  <CreditCardIcon
                    color="#02006E"
                    style={{
                      marginLeft: "15px",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                  <TextFieldV2
                    label="Salary"
                    hiddenLabel
                    variant="filled"
                    name="salary"
                    id="salary"
                    type="text"
                    onChange={handleInputChange}
                    // error={checkError("email", "textField")}
                    value={salaryDetails?.salary}
                    height="45px"
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>
                <Typography
                  sx={{
                    position: "absolute",
                    bottom: "-24px",
                    fontFamily: "Poppins-Regular",
                    fontSize: "12px",
                    fontWeight: "400",
                    lineHeight: "16px",
                    color: "#212231",
                    pl: "20px",
                  }}
                >
                  $ {getFieldValue(costToCompany)} + $ {getFieldValue(salaryDetails?.raiseSalaryAmount)}
                </Typography>
              </FormControl>
            </Grid>
            <Grid sx={{ display: "flex", alignItems: "center", gap: "40px" }}>
              <Typography
                sx={{
                  fontFamily: "Poppins-Regular",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "20px",
                  color: "#212231",
                  width: "200px",
                }}
              >
                Or, enter % Increase
              </Typography>
              <FormControl sx={{ position: "relative" }} variant="outlined" error>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #B2B4E2",
                    borderRadius: "12px",
                    maxWidth: "318px",
                  }}
                >
                  <CreditCardIcon
                    color="#02006E"
                    style={{
                      marginLeft: "15px",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                  <TextFieldV2
                    label="Per Increase Address"
                    hiddenLabel
                    variant="filled"
                    name="perIncrease"
                    id="perIncrease"
                    type="text"
                    height="45px"
                    value={salaryDetails?.perIncrease}
                    onChange={handleInputChange}
                    // error={checkError("email", "textField")}
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>
              </FormControl>
            </Grid>
            <Grid sx={{ display: "flex", alignItems: "center", gap: "40px" }}>
              <Typography
                sx={{
                  fontFamily: "Poppins-Regular",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "20px",
                  color: "#212231",
                  width: "200px",
                }}
              >
                Bonus Value
              </Typography>
              <FormControl sx={{ position: "relative" }} variant="outlined" error>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #B2B4E2",
                    borderRadius: "12px",
                    maxWidth: "318px",
                  }}
                >
                  <GiftIcon
                    color="#02006E"
                    style={{
                      marginBottom: "4px",
                      marginLeft: "15px",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                  <TextFieldV2
                    label="Salary Bonus"
                    hiddenLabel
                    variant="filled"
                    name="bonus"
                    height="45px"
                    id="bonus"
                    type="text"
                    value={salaryDetails?.bonus}
                    onChange={handleInputChange}
                    // error={checkError("email", "textField")}
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          sx={{
            maxWidth: "894px",
            width: "100%",
            margin: "20px auto",
            display: "flex",
            alignItems: "center",
            gap: "12px",
            justifyContent: "end",
          }}
        >
          <AlertError
            openAlert={openAlert}
            setOpenAlert={setOpenAlert}
            errors={[giveRaiseInfo]}
            negitiveIncrement={salaryDetails?.raiseSalaryAmount}
          />
          <Button
            variant="outlined"
            size="lg"
            style={{
              color: "#4950C7",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "20px",
            }}
            onClick={() => {
              setTab("Overview");
              setOpenAlert(false);
              dispatch(ResetGiveRaise({}));
            }}
          >
            Cancel
          </Button>
          <Button variant="primary" size="lg" onClick={handleSave}>
            Save all edits
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default EditSalary;
