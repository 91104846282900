import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  Paper,
  Box,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import "./TableComponent.scss";

function TableComponent({
  columns,
  data,
  onSort,
  onRowClick,
  onPageChange,
  onRowsPerPageChange,
  totalCount,
  rowsPerPageOptions = [10, 25, 50],
  initialRowsPerPage = 10,
  tableContainerProps,
  tableProps,
  onAction,
}) {
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    if (onSort) {
      onSort(property, isAsc ? "desc" : "asc");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (onPageChange) {
      onPageChange(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    if (onRowsPerPageChange) {
      onRowsPerPageChange(newRowsPerPage);
    }
  };

  const handleMenuOpen = (event, row) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleAction = (action) => {
    if (onAction && selectedRow) {
      onAction(action, selectedRow);
    }
    handleMenuClose();
  };

  useEffect(() => {
    setPage(0);
  }, [data]);

  const renderCellContent = (column, row) => {
    const value = row[column.id];
    let content;
    if (column.render) {
      content = column.render(value, row);
    } else if (column.type === "action") {
      content = (
        <Box>
          {column.actions.map((action) => (
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                onAction(action, row);
              }}
            >
              {action.icon}
            </IconButton>
          ))}
          {column.moreActions && (
            <IconButton size="small" onClick={(e) => handleMenuOpen(e, row)}>
              <MoreVertIcon className="three-dots" />
            </IconButton>
          )}
        </Box>
      );
    } else {
      content = value;
    }

    return <div className="table-cell-content">{content}</div>;
  };

  return (
    <Paper className="table-component">
      <TableContainer {...tableContainerProps}>
        <Table {...tableProps}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align || "left"}
                  style={{ minWidth: column.minWidth, ...column.headStyle }}
                  sortDirection={orderBy === column.id ? order : false}
                  className="table-head-cell"
                >
                  {column.sortable !== false ? (
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : "asc"}
                      onClick={() => handleRequestSort(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  ) : (
                    column.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <TableRow
                hover
                onClick={() => onRowClick && onRowClick(row)}
                role="checkbox"
                tabIndex={-1}
                key={row.id || index}
                className="table-body-row"
              >
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align} style={column.cellStyle} className="table-body-cell">
                    {renderCellContent(column, row)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={totalCount || data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className="table-componet-pagination"
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          className: "action-menu",
        }}
      >
        {columns
          .find((col) => col.type === "action")
          ?.moreActions?.map((action) => (
            <MenuItem
              onClick={() => handleAction(action)}
              className={`paragraph action-menu-item ${action.label == "Delete" ? "delete-item" : ""}`}
            >
              {action.icon && (
                <Box component="span" mr={1}>
                  {action.icon}
                </Box>
              )}
              {action.label}
            </MenuItem>
          ))}
      </Menu>
    </Paper>
  );
}

export default TableComponent;
