import getRequest from "util/APIHelperGet";
import { baseUrl } from "util/APIBaseUrl";
import postRequest from "util/APIHelperPost";
import deleteRequest from "util/ApiHelperDelete";

import { showSnackbar } from "store/actions/Utility";

import { AlertType } from "components/common/Snackbar";

export const getEmployeeExperience = async (employeeId) => {
  const educationResp = await getRequest(`${baseUrl}/employee/${employeeId}/experience`);
  const { ok, payload } = educationResp;
  if (ok) {
    return payload;
  }
  return null;
};

export const addEmployeeExperience = async (employeeId, payload, dispatch) => {
  const postUrl = `${baseUrl}/employee/${employeeId}/experience`;

  try {
    const response = await postRequest(postUrl, JSON.stringify(payload), true, "POST");

    if (response.status === 200) {
      dispatch(showSnackbar({ type: AlertType.success, message: "Experience Added Successfully", dispatch }));
      return { success: true };
    }
    return { success: false };
  } catch (error) {
    dispatch(showSnackbar({ type: AlertType.error, message: error?.message, dispatch }));
    return { success: false };
  }
};

export const editEmployeeExperience = async (employeeId, data, experienceId, dispatch) => {
  try {
    const response = await postRequest(
      `${baseUrl}/employee/${employeeId}/experience/${experienceId}`,
      JSON.stringify(data),
      false,
      "PUT"
    );
    dispatch(showSnackbar({ type: AlertType.success, message: "Experience Edited Successfully", dispatch }));
    return response;
  } catch (error) {
    dispatch(showSnackbar({ type: AlertType.error, message: "Failed to edit employee experience", dispatch }));
    throw error;
  }
};

export const editEdgeExperience = async (employeeId, data, experienceId, dispatch) => {
  try {
    const response = await postRequest(
      `${baseUrl}/employee/${employeeId}/employmentHistory/${experienceId}`,
      JSON.stringify(data),
      false,
      "PUT"
    );
    dispatch(showSnackbar({ type: AlertType.success, message: "Experience Edited Successfully", dispatch }));

    return response;
  } catch (error) {
    dispatch(showSnackbar({ type: AlertType.error, message: "Failed to edit edge experience", dispatch }));
    throw error;
  }
};

export const deleteEmployeeExperience = async (employeeId, experienceId, dispatch) => {
  try {
    await deleteRequest(`${baseUrl}/employee/${employeeId}/experience/${experienceId}`);
    dispatch(showSnackbar({ type: AlertType.success, message: "Experience Deleted Successfully", dispatch }));
    return { success: true };
  } catch (error) {
    dispatch(showSnackbar({ type: AlertType.error, message: "Failed to delete experience", dispatch }));
    throw error;
  }
};
