import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import moment from "moment";

import PageLoader from "components/common/PageLoader";
import TableComponent from "components/common/TableComponent";
import { AlertType } from "components/common/Snackbar";
import DocumentModal from "../DocumentModal/DocumentModal";
import DocumentDeleteModal from "../DocumentDeleteModal/DocumentDeleteModal";
import NoDocumentScreen from "../NoDocumentScreen/NoDocumentScreen";
import DocumentTopBar from "../DocumentTopBar/DocumentTopBar";

import {
  downloadEmployeeDocument,
  getEmployeeDocumentList,
  updateEmployeeDocumentInfo,
  saveEmployeeDocumentInfo,
} from "services/BambooHrTabs/documents";

import { showSnackbar } from "store/actions/Utility";
import { getFileSizeToDisplay } from "util/utils";

import "../Document.scss";

function DocumentList({
  employeeId,
  loggedInUserType,
  documentType,
  refreshEmployeeList,
  openModalState,
  setOpenModal,
  onBackClicked,
}) {
  const dispatch = useDispatch();

  const [tableData, setTableData] = useState([]);
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editRowData, setEditRowData] = useState(null);
  const [refreshList, setRefreshList] = useState(0);
  const [modalType, setModalType] = useState("add");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (openModalState) {
      setShowDocumentModal(true);
      setModalType("add");
      setEditRowData(null);
      setOpenModal(false);
    }
  }, [openModalState, setOpenModal]);

  const employeeDocumentList = useCallback(async () => {
    try {
      setIsLoading(true);
      const documentList = await getEmployeeDocumentList(employeeId, limit, page, orderBy, {
        documentType: [documentType],
      });
      setTableData(documentList);
    } catch (error) {
      dispatch(showSnackbar({ type: AlertType.error, message: error?.message, dispatch }));
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, limit, orderBy, page, employeeId, documentType]);

  useEffect(() => {
    employeeDocumentList();
  }, [refreshList, employeeDocumentList]);

  async function downloadDocument(document) {
    try {
      const res = await downloadEmployeeDocument(employeeId, document?.documentId);
      const { fileUrl } = res;
      if (!fileUrl) {
        throw new Error("File URL not found");
      }

      const response = await fetch(res.fileUrl);
      const blob = await response.blob();
      const link = window.document.createElement("a");
      const url = window.URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute("download", document?.documentName);
      window.document.body.appendChild(link);
      link.click();
      window.document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      dispatch(showSnackbar({ type: AlertType.error, message: error?.message, dispatch }));
    }
  }

  const handleUpdate = async ({ fileName, shareWithEmployee, shareWithCustomer }) => {
    const payload = {
      documentName: fileName,
      documentType,
      shareWithEmployee,
      shareWithCustomer,
    };
    try {
      setIsLoading(true);
      await updateEmployeeDocumentInfo(employeeId, editRowData?.documentId, payload);
      setRefreshList(refreshList + 1);
      dispatch(showSnackbar({ type: AlertType.success, message: "Document updated successfully", dispatch }));
    } catch (error) {
      dispatch(showSnackbar({ type: AlertType.error, message: error?.message, dispatch }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async ({ fileName, updatedFileName, selectedFile, shareWithEmployee, shareWithCustomer }) => {
    setIsLoading(true);
    const payload = {
      fileName: updatedFileName,
      documentName: fileName,
      fileType: selectedFile.type,
      size: selectedFile.size,
      documentType,
      shareWithEmployee,
      shareWithCustomer,
    };
    try {
      await saveEmployeeDocumentInfo(employeeId, payload);

      setRefreshList(refreshList + 1);
      dispatch(showSnackbar({ type: AlertType.success, message: "Document added successfully", dispatch }));
    } catch (error) {
      dispatch(showSnackbar({ type: AlertType.error, message: error?.message, dispatch }));
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      id: "documentName",
      label: "Name",
      minWidth: 200,
      sortable: true,
      render: (value) => (
        <Box display="flex" alignItems="center">
          <PictureAsPdfOutlinedIcon color="primary" sx={{ marginRight: 1.5 }} />
          <p className="para-body-m-regular">{value}</p>
        </Box>
      ),
    },
    {
      id: "type",
      label: "Type",
      minWidth: 100,
      render: (value) => {
        return value === "application/pdf" ? "PDF" : value;
      },
    },
    {
      id: "size",
      label: "Size",
      minWidth: 100,
      render: (value) => getFileSizeToDisplay(value),
    },
    {
      id: "createdAt",
      label: "Added On",
      minWidth: 150,
      render: (value) => moment(value).format("DD MMM, YYYY"),
    },
    {
      id: "action",
      type: "action",
      actions: [{ icon: <FileDownloadOutlinedIcon />, label: "Download" }],
      moreActions: (() => {
        if (loggedInUserType !== "edge") {
          return null;
        }
        if (documentType === "Signed Documents") {
          return [{ label: "Edit" }];
        }
        return [{ label: "Edit" }, { label: "Delete" }];
      })(),
      minWidth: 100,
      align: "right",
    },
  ];

  const handleAction = (action, row) => {
    if (action.label === "Download") {
      downloadDocument(row);
    } else if (action.label === "Edit") {
      setEditRowData(row);
      setShowDocumentModal(true);
      setModalType("edit");
    } else if (action.label === "Delete") {
      setEditRowData(row);
      setShowDeleteModal(true);
    }
  };

  const handleSort = (property, direction) => {
    const fieldMap = {
      documentName: "Document_Name",
      type: "Document_Extension",
      size: "Size",
      createdAt: "createdAt",
    };
    if (fieldMap[property]) {
      setOrderBy({ field: fieldMap[property], direction });
    }
  };

  return (
    <div className="w-full">
      <div className="ml-2">
        <DocumentTopBar
          onAddButtonClick={() => {
            setShowDocumentModal(true);
            setEditRowData(null);
            setModalType("add");
          }}
          onBackClicked={onBackClicked}
          folderName={documentType}
          showAddButton={!!tableData?.count && loggedInUserType == "edge"}
        />
      </div>
      <div>
        {isLoading && <PageLoader />}

        {tableData?.count > 0 && (
          <TableComponent
            columns={columns}
            data={tableData?.documents}
            onSort={handleSort}
            onPageChange={(newPage) => setPage(newPage)}
            onRowsPerPageChange={(newRowsPerPage) => setLimit(newRowsPerPage)}
            totalCount={tableData?.count}
            tableProps={{ stickyHeader: true }}
            onAction={handleAction}
          />
        )}

        {!isLoading && tableData?.count === 0 && (
          <NoDocumentScreen
            onAddDocumentButtonClicked={() => {
              setShowDocumentModal(true);
              setEditRowData(null);
              setModalType("add");
            }}
            documentType={documentType}
            loggedInUserType={loggedInUserType}
            refreshEmployeeList={refreshEmployeeList}
          />
        )}
        <DocumentModal
          type={modalType}
          open={showDocumentModal}
          onClose={() => {
            setShowDocumentModal(false);
          }}
          onSave={modalType === "edit" ? handleUpdate : handleSave}
          modalData={editRowData}
          isLoading={isLoading}
          documentType={documentType}
        />
        <DocumentDeleteModal
          employeeId={employeeId}
          open={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          document={editRowData}
          onPostDelete={() => {
            setRefreshList(refreshList + 1);
            setShowDeleteModal(false);
          }}
        />
      </div>
    </div>
  );
}

export default DocumentList;
