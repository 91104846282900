import React from "react";
import { useSelector } from "react-redux";

function TimeoffNotAvailable() {
  const getEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.getEmployeeDetails);
  const platformConfig = useSelector((state) => state.PlatformConfig.platformConfig);

  const timeOffAllowedCountries =
    localStorage.getItem("TIMEOFF_ALLOWED_COUNTRIES") || platformConfig?.payload?.TIMEOFF_ALLOWED_COUNTRIES || [];

  return (
    <div className="time-off-not-available">
      {!getEmployeeDetails?.payload?.timeoffFlag &&
      timeOffAllowedCountries?.includes(getEmployeeDetails?.payload?.country)
        ? "Time-off is disabled for you. Please contact your administrator."
        : `Time-off feature is currently only available for employees based in Pakistan. We're
      working to bring this feature for employees based in Peru soon. Meanwhile, for Peruvian employees, please continue
      following the same process as was being followed before.`}
    </div>
  );
}

export default TimeoffNotAvailable;
