import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { usePermissions } from "components/Hook";
import TopHeader from "components/common/TopHeader";
import PageLoader from "components/common/PageLoader";
import TimeoffTab from "components/Screens/Workforce/UserDetailsV2/Timeoff";
import ProfileTab from "components/Screens/Workforce/UserDetailsV2/ProfileTab";
import DocumentsTab from "components/Widgets/BambooHRTabs/Documents";
import ExperienceTab from "components/Widgets/BambooHRTabs/Experience";
import EducationTab from "components/Widgets/BambooHRTabs/Education";
import EditSalary from "components/Screens/Workforce/UserDetailsV2/EditSalary";
import PayrollHistoryTab from "components/Screens/Workforce/UserDetailsV2/PayrollHistoryTab";
import ProfileStrip from "components/common/ProfileStrip";
import TimeoffNotAvailable from "components/Screens/Workforce/UserDetailsV2/Timeoff/TimeoffNotavailable";
import {
  getFieldValue,
  getMonthFromDate,
  getValue,
  isEmptyObject,
  isValidResponse,
} from "components/Screens/CommonUtils";
import ToolBar from "components/common/PageToolbar";
import { WORKFORCE_PROFILE_TABS } from "constants";

import { employeeSalaryDetails } from "store/actions/Workforce/EmployeeDetils/SalaryDetails";
import { employeeSalaryInfo } from "store/actions/Workforce/EmployeeDetils/SalaryInfo";
import { GetEmployeeDetails } from "store/actions/GetEmployeeDetails";

import "./user-details.css";

function UserDetails() {
  const dispatch = useDispatch();
  const params = useParams();
  const [currentTab, setCurrentTab] = useState(WORKFORCE_PROFILE_TABS[0]?.title);

  const { loggedInUser, loggedInUserType } = usePermissions();

  const salaryInfo = useSelector((state) => state.SalaryInfo.employeeSalaryInfo);
  const platformConfig = useSelector((state) => state.PlatformConfig.platformConfig);
  const getEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.getEmployeeDetails);
  const loadingEmployeeSalaryInfo = useSelector((state) => state.SalaryInfo.loadingEmployeeSalaryInfo);
  const loadingGetEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.loadingGET_EMPLOYEE_Details);

  const timeOffAllowedCountries =
    localStorage.getItem("TIMEOFF_ALLOWED_COUNTRIES") || platformConfig?.payload?.TIMEOFF_ALLOWED_COUNTRIES || [];

  const employeeResp = getEmployeeDetails?.payload;

  useEffect(() => {
    if (isEmptyObject(loggedInUser) && isValidResponse(getEmployeeDetails)) {
      const customerId = loggedInUser?.customerId;
      const employeeId = employeeResp?.employeeId;

      const salaryInfoRequest = {
        customer: `customer/${customerId}/employee/${employeeId}/salaryInfo`,
        edge: `employee/${employeeId}/salary`,
      };

      const salaryDetailsRequest = {
        customer: `customer/${customerId}/employee/${employeeId}/payroll`,
        edge: `employee/${employeeId}/payroll`,
      };

      dispatch(
        employeeSalaryDetails({
          request: salaryDetailsRequest[loggedInUserType],
        })
      );

      dispatch(
        employeeSalaryInfo({
          request: salaryInfoRequest[loggedInUserType],
        })
      );
    }
  }, [getEmployeeDetails]);

  useEffect(() => {
    let request = {};
    if (isEmptyObject(loggedInUser && params?.id)) {
      request = {
        customer: `customer/${loggedInUser?.customerId}/employee/${params?.id}`,
        edge: `employee/${params?.id}`,
      };

      dispatch(
        GetEmployeeDetails({
          request: request[loggedInUserType],
        })
      );
    }
  }, []);

  useEffect(() => {
    const tabKey = decodeURIComponent(window?.location.hash.replace(/#/g, "").trim()).toLowerCase();

    const matchedTab = WORKFORCE_PROFILE_TABS.find((tab) => tab.key === tabKey);
    if (matchedTab) {
      setCurrentTab(matchedTab.title);
    } else {
      setCurrentTab(WORKFORCE_PROFILE_TABS[0].title);
    }
  }, [window?.location.hash]);

  const handleEditSalary = () => setCurrentTab("edit-salary");

  return (
    <>
      <Helmet>
        <title>{`Edge | Employees - ${getValue(employeeResp?.firstName)} ${getValue(employeeResp?.lastName)}`}</title>
      </Helmet>
      {(loadingGetEmployeeDetails || loadingEmployeeSalaryInfo) && <PageLoader />}
      <div className="container">
        <TopHeader
          title={
            currentTab !== "edit-salary"
              ? `${getValue(employeeResp?.firstName)} ${getValue(employeeResp?.lastName)}`
              : `Edit Salary for ${getMonthFromDate(salaryInfo?.payload?.paymentDate)}`
          }
          breadcrumbs
          primaryTitle="Workforce"
          primaryLink="/main/workforce"
          linkName="Profile"
          backIcon="/main/workforce"
          primaryBtn={currentTab !== "edit-salary" && "Edit Salary"}
          primaryBtnClick={handleEditSalary}
        />

        {currentTab === "edit-salary" ? (
          <EditSalary
            setTab={setCurrentTab}
            costToCompany={
              employeeResp?.costToCompany
                ? getFieldValue(employeeResp?.costToCompany) -
                  getFieldValue(employeeResp?.incrementDetails?.bonus) -
                  getFieldValue(employeeResp.incrementDetails?.incrementAmount)
                : "-"
            }
          />
        ) : (
          <div className="content-container">
            <div className="content-inner">
              <ProfileStrip userData={getEmployeeDetails?.payload} image edgePoc leaveStatus />
              <div className="w-full">
                <ToolBar tabs={WORKFORCE_PROFILE_TABS} currentTab={currentTab} setCurrentTab={setCurrentTab} />
                <div className="pt-15">
                  {currentTab === "Overview" && <ProfileTab />}
                  {currentTab === "Documents" && <DocumentsTab noPadding />}
                  {currentTab === "Payroll History" && <PayrollHistoryTab />}
                  {currentTab === "Education" && <EducationTab employeeId={employeeResp?.employeeId} />}
                  {currentTab === "Experience" && <ExperienceTab employeeId={employeeResp?.employeeId} />}
                  {currentTab === "Time Off" &&
                    (!timeOffAllowedCountries?.includes(employeeResp?.country) || !employeeResp?.timeoffFlag ? (
                      <TimeoffNotAvailable />
                    ) : (
                      <TimeoffTab />
                    ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default UserDetails;
