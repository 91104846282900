import React, { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar } from "@mui/material";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";

import {
  convertDate,
  getCountryFlag,
  getProfileImage,
  getSalaryWithoutBouns,
  getValue,
  isValidResponse,
} from "components/Screens/CommonUtils";
import ImageCropperModal from "components/common/ImageCropperModal";

import YellowDotIcon from "assets/svgs/YellowDotIcon";
import RedDotIcon from "assets/svgs/RedDotIcon";

import { uploadWorkforcePhoto } from "services/Admin/Dashboard";
import { baseUrl } from "util/APIBaseUrl";
import { GetEmployeeById } from "store/actions/EmployeePortal/GetEmployeeDetials";
import { usePermissions } from "components/Hook";

import "./profile-strip.css";

function UserPersonalInfo({ userData, leaveStatus, edgePoc, image, showIcon = false, type = "customer" }) {
  const [imageSrc, setImageSrc] = useState(null);
  const [isFileUploadError, setIsFileUploadError] = useState(false);
  const [showImageCropperModal, setShowImageCropperModal] = useState(false);

  const dispatch = useDispatch();
  const photoInput = createRef();
  const { loggedInUser } = usePermissions();

  const getEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.getEmployeeDetails);
  const getPhoneNumber = (number) => number || "-";

  useEffect(() => {
    if (isValidResponse(userData)) {
      if (userData.picturePath) {
        getProfileImage(userData.employeeId, userData?.pictureVersion).then((img) => {
          setImageSrc(img);
        });
      }
    }
  }, [userData]);

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  const handleImageChange = async (e) => {
    e.preventDefault();

    const file = e.target.files[0];
    e.target.value = "";

    if (file.size / 1024 / 1024 > 2) {
      setIsFileUploadError(true);
      return;
    }
    setIsFileUploadError(false);

    const imageDataUrl = await readFile(file);
    setImageSrc(imageDataUrl);

    setShowImageCropperModal(true);
  };

  const uploadProfilePicture = async (param) => {
    const formData = new FormData();
    formData?.append("file", param);
    setShowImageCropperModal(false);

    await uploadWorkforcePhoto({ request: `/employee/${userData?.employeeId}/logo`, formData });
    dispatch(GetEmployeeById({ employeeId: loggedInUser?.userId }));
  };

  return (
    <>
      <div className="custom-grid-container">
        {image && (
          <div className="image-container">
            <Avatar
              className="custom-card-media"
              imgProps={{ crossOrigin: "anonymous" }}
              src={`${baseUrl}/employee/${userData?.employeeId}/logo?id=${userData?.pictureVersion}`}
            />
            {showIcon && (
              <div className="icon-overlay">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  ref={photoInput}
                  style={{ display: "none" }}
                />
                <CameraAltOutlinedIcon sx={{ color: "#3D43BB" }} onClick={() => photoInput.current.click()} />
              </div>
            )}
          </div>
        )}
        <div className="w-full">
          <div className="mt-10p">
            {edgePoc && <p className="overline-md text-headings-secondary">Edge Point of Contact</p>}
            <p className="heading-h4-semibold">{`${getValue(userData?.firstName)} ${getValue(userData?.lastName)}`}</p>
            <p className="heading-h5-medium color-text-primary">{getValue(userData?.jobTitle, "N/A")}</p>
          </div>

          {type === "customer" && userData?.leaveStatus?.status && leaveStatus && (
            <div className="custom-status-container mt-10p">
              {userData?.leaveStatus?.statusId === "Out till" || userData?.leaveStatus?.statusId === "Out today" ? (
                <RedDotIcon />
              ) : (
                userData?.leaveStatus?.statusId === "Out from" && <YellowDotIcon />
              )}

              <p className="para-body-s-regular">
                {`${userData?.leaveStatus?.statusId === "Out today" ? userData?.leaveStatus?.statusId : userData?.leaveStatus?.status}`}
              </p>
            </div>
          )}
          <div className="mt-15">
            {type === "customer" ? (
              <div className="custom-section-container">
                <div className="section-child">
                  <p className="para-body-xs-regular pb-2p color-text-highlight">Contact</p>
                  <p className="para-body-s-regular">{getPhoneNumber(userData?.phoneNumber)}</p>
                </div>
                <div className="section-child">
                  <p className="para-body-xs-regular color-text-highlight">Country</p>
                  <div className="flex">
                    <div className="w-1 h-1">{getCountryFlag(userData?.country, 14, 14)}</div>
                    <p className="para-body-s-regular pt-2p pl-025">{userData?.country}</p>
                  </div>
                </div>
                <div className="section-child">
                  <p className="para-body-xs-regular pb-2p color-text-highlight">Gender</p>
                  <p className="para-body-s-regular">{getValue(userData?.gender)}</p>
                </div>
                <div className="section-child">
                  <p className="para-body-xs-regular pb-2p color-text-highlight">Date of Birth</p>
                  <p className="para-body-s-regular">{convertDate(getEmployeeDetails.payload?.dateOfBirth)}</p>
                </div>
                <div className="section-child">
                  <p className="para-body-xs-regular pb-2p color-text-highlight">Salary</p>
                  <p className="para-body-s-regular">
                    $ {getSalaryWithoutBouns(userData?.costToCompany, userData?.incrementDetails?.bonus)}
                  </p>
                </div>
                <div className="section-child">
                  <p className="para-body-xs-regular pb-2p color-text-highlight">Department</p>
                  <p className="para-body-s-regular">{getValue(userData?.department, "N/A")}</p>
                </div>
              </div>
            ) : (
              <div className="custom-section-container">
                <div className="section-child">
                  <p className="para-body-xs-regular pb-2p color-text-highlight">Employee Success Manager</p>
                  <p className="para-body-s-regular">
                    {userData?.hrpoc?.First_Name && userData?.hrpoc?.Last_Name
                      ? `${userData.hrpoc.First_Name} ${userData.hrpoc.Last_Name}`
                      : "N/A"}
                  </p>
                </div>
                <div className="section-child">
                  <p className="para-body-xs-regular pb-2p color-text-highlight">Hire Date</p>
                  <p className="para-body-s-regular">{convertDate(userData?.edgeJoiningDate)}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ImageCropperModal
        open={showImageCropperModal}
        onClose={() => setShowImageCropperModal(false)}
        imageSrc={imageSrc}
        onSave={uploadProfilePicture}
        isFileUploadError={isFileUploadError}
        errorMessage="The file you've selected is too large. Please upload an image file file that is 2MB or smaller."
      />
    </>
  );
}

export default UserPersonalInfo;
