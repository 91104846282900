import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Switch } from "@mui/material";

import CustomModal from "components/common/Modal";
import Select from "components/common/Select";
import Text from "components/common/Text";
import CustomDatePicker from "components/common/DatePicker";
import Button from "components/common/Button";

import { GENDERS } from "constants";

import EmploymentInformationSchema from "validations/BambooHRTabs/EmploymentInformationSchema";

import { assignEmployeeToACustomer } from "services/Admin/Dashboard";

import "./AssignToCustomerModal.scss";

function AssignToCustomerModal({ open, closeModal, employeeId, initialPersonalDataWorkforce }) {
  const methods = useForm({
    resolver: yupResolver(EmploymentInformationSchema),
    mode: "onChange",
    values: initialPersonalDataWorkforce,
  });
  const { handleSubmit, watch } = methods;

  const successManagersList = watch("successManagersList") || [];

  const onSubmit = async (data) => {
    assignEmployeeToACustomer(employeeId, data);
  };

  return (
    <CustomModal open={open} title="Assign to Customer" width="700px" onClose={closeModal}>
      <FormProvider {...methods}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit((data) => onSubmit(data, e))(e);
          }}
        >
          <div className="flex flex-col gap-1">
            <div className="flex gap-1">
              <Select label="Company" name="company" placeholder="Search by company name" options={GENDERS} />
            </div>

            <div className="flex gap-1">
              <Select label="Department" name="department" placeholder="Select" options={GENDERS} />
              <Select
                label="Manager"
                name="manager"
                isOptional
                placeholder="Search by company name"
                options={successManagersList}
              />
            </div>

            <div className="flex gap-1">
              <Text label="Job title" name="jobTitle" placeholder="e.g. Client Associate" />
              <CustomDatePicker name="dateOfJoining" label="Date of joining" />
            </div>

            <div className="flex gap-1">
              <Select label="Employment type" name="employmentType" placeholder="Select" options={GENDERS} />
              <div className="w-100p">
                <div className="para-body-m-medium text-headings-secondary">Edge wallet</div>
                <div className="flex justify-between">
                  <span className="para-body-l-regular align-self-center">Disabled</span>
                  <span>
                    <Switch name="edgeWallet" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-action-buttons">
            <Button onClick={closeModal} label="Cancel" variant="outlined" size="large" />
            <Button type="submit" label="Save" variant="contained" size="large" />
          </div>
        </form>
      </FormProvider>
    </CustomModal>
  );
}

export default AssignToCustomerModal;
