import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";

import CustomModal from "components/common/Modal";
import Select from "components/common/Select";
import Text from "components/common/Text";
import CustomDatePicker from "components/common/DatePicker";
import Button from "components/common/Button";
import { AlertType } from "components/common/Snackbar";

import { GENDERS } from "constants";

import EditTalentInfoSchema from "validations/Admin/EditTalentInfoSchema";
import EmployeePersonalInfoSchema from "validations/BambooHRTabs/EmployeePersonalInfoSchema";

import { getEmployeeSuccessManagersList, updateEmployeePersonalDetails } from "services/Admin/Dashboard";
import { updateEmployeePersonalInfo } from "services/BambooHrTabs/about";

import { showSnackbar } from "store/actions/Utility";
import { GetEmployeeDetails } from "store/actions/GetEmployeeDetails";

import "./EditPersonalInfoModal.scss";

function EditPersonalInfoModal({ open, closeModal, initialPersonalDataWorkforce, type = "admin" }) {
  const dispatch = useDispatch();
  const countriesList = useMemo(() => JSON.parse(sessionStorage.getItem("dropdownData"))?.payload?.country || [], []);
  const countries = countriesList?.map(({ countryCode }) => ({
    value: countryCode,
    label: countryCode,
  }));

  const methods = useForm({
    resolver: yupResolver(type === "admin" ? EditTalentInfoSchema : EmployeePersonalInfoSchema),
    mode: "onChange",
    values: initialPersonalDataWorkforce,
  });
  const { handleSubmit } = methods;

  const [isUpdatingData, setIsUpdatingData] = useState(false);
  const [successManagersList, setSuccessManagersList] = useState(false);

  // eslint-disable-next-line consistent-return
  const onSubmit = async (data) => {
    if (type === "employee") {
      try {
        await updateEmployeePersonalInfo(initialPersonalDataWorkforce?.employeeId, {
          gender: data.gender,
          dateOfBirth: moment(data.dateOfBirth).format("YYYY-MM-DD"),
        });
        return dispatch(showSnackbar({ type: AlertType.success, message: "Profile updated successfully", dispatch }));
      } catch (error) {
        return dispatch(showSnackbar({ type: AlertType.error, message: error?.message, dispatch }));
      }
    }

    setIsUpdatingData(true);

    if (type === "admin") {
      const {
        nationalID: cnic,
        dateOfBirth,
        firstName,
        middleName,
        lastName,
        jobTitle,
        employeeSuccessManager,
        country,
        gender,
      } = data;

      const res = await updateEmployeePersonalDetails({
        firstName,
        lastName,
        country,
        cnic_dni_cex: cnic,
        gender,
        dateOfBirth: moment(dateOfBirth).format("YYYY-MM-DD"),
        jobTitle,
        edgeHREmployeeID: successManagersList.find((manager) => employeeSuccessManager == manager?.label)?.userId,
        employeeId: initialPersonalDataWorkforce?.employeeId,
        ...(middleName && { middleName }),
      });
      const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

      if (res.payload.error) {
        setIsUpdatingData(false);
        return dispatch(
          showSnackbar({
            type: AlertType.error,
            message: res.payload?.error?.[0]?.msg ?? "An error occurred",
            dispatch,
          })
        );
      }
      dispatch(
        GetEmployeeDetails({
          customerID: loggedInUser?.userId,
          employeeID: initialPersonalDataWorkforce?.employeeId,
          request: `employee/${initialPersonalDataWorkforce?.employeeId}`,
        })
      );
      dispatch(showSnackbar({ type: AlertType.success, message: "Personal information updated", dispatch }));

      setIsUpdatingData(false);
      closeModal();
    }
  };

  const fetchEmployeeSuccessManagersList = async () => {
    const res = await getEmployeeSuccessManagersList();
    const refinedManagersList = res?.rows?.map(({ firstName, lastName, userId }) => ({
      label: firstName + lastName,
      value: firstName + lastName,
      userId,
    }));

    setSuccessManagersList(refinedManagersList);
  };

  useEffect(() => {
    if (type === "admin") {
      fetchEmployeeSuccessManagersList();
    }
  }, []);

  return (
    <CustomModal open={open} title="Personal" width="700px" onClose={closeModal}>
      <FormProvider {...methods}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit((data) => onSubmit(data, e))(e);
          }}
        >
          <div className="flex flex-col gap-1">
            <div className="flex gap-1">
              <Text label="First Name" name="firstName" placeholder="e.g. John" disabled={type === "employee"} />
              <Text
                label="Middle Name (Optional)"
                name="middleName"
                placeholder="e.g. Doe"
                disabled={type === "employee"}
              />
              <Text label="Last Name " name="lastName" placeholder="e.g. Smith" disabled={type === "employee"} />
            </div>

            <div className="flex gap-1">
              <Text
                label="Job title"
                name="jobTitle"
                placeholder="e.g. Client Associate"
                disabled={type === "employee"}
              />
              <Select
                label="Employee Success Manager"
                name="employeeSuccessManager"
                value={
                  type === "employee" &&
                  initialPersonalDataWorkforce?.hrpoc &&
                  `${initialPersonalDataWorkforce?.hrpoc?.First_Name} ${initialPersonalDataWorkforce?.hrpoc?.Last_Name}`
                }
                placeholder="Search by name or email address"
                options={successManagersList}
                disabled={type === "employee"}
              />
            </div>

            <div className="flex gap-1">
              <Select
                label="Country"
                name="country"
                placeholder="Select country"
                options={countries}
                disabled={type === "employee"}
              />
              <Text
                label="National ID"
                name="nationalID"
                placeholder="CNIC, DNI, etc."
                disabled={type === "employee"}
              />
            </div>

            <div className="flex gap-1">
              <Select label="Gender" name="gender" placeholder="Select" options={GENDERS} />
              <CustomDatePicker name="dateOfBirth" label="Date of birth" />
            </div>
          </div>
          <div className="modal-action-buttons">
            <Button onClick={closeModal} label="Cancel" variant="outlined" size="large" />
            <Button type="submit" isLoading={isUpdatingData} label="Save" variant="contained" size="large" />
          </div>
        </form>
      </FormProvider>
    </CustomModal>
  );
}

export default EditPersonalInfoModal;
