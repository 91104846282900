import React from "react";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import Button from "components/common/Button";
import "../Document.scss";

function NoDocumentScreen({ onAddDocumentButtonClicked, loggedInUserType }) {
  if (loggedInUserType === "edge") {
    return (
      <div className="flex flex-col justify-center items-center w-full mb-1 mr-3">
        <InsertDriveFileOutlinedIcon className="no-document-icon" />
        <p className="para-body-m-regular mt-05">No document found.</p>
        <p className="mt-1">
          <Button label="Add Document" variant="outlined" size="small" onClick={onAddDocumentButtonClicked} />
        </p>
      </div>
    );
  }

  return (
    <div className="no-documents-shared flex items-center justify-center px-15 py-1">
      <p className="para-body-l-regular no-documents-shared-text">No document shared yet</p>
    </div>
  );
}

export default NoDocumentScreen;
