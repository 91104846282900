import React from "react";
import { IconButton, Stack, Tooltip } from "@mui/material";
import PeruIcon from "components/Screens/Timeoff/TimeoffAdmin/EmployeeTimeOffDetails/Icons/PeruIcon";
import PakistanIcon from "components/Screens/Timeoff/TimeoffAdmin/EmployeeTimeOffDetails/Icons/PakistanIcon";
import AvatarIcon from "components/Icons/AvatarIcon";

function EmployeeInfoTop(props) {
  const { img, name, jobTitle, country, size, hiredDate, managedBy } = props;
  const avatarSize = size || {
    width: "65.5px",
    height: "65.5px",
    borderRadius: "50%",
  };
  return (
    <Stack direction="row" alignItems="center" spacing="10px">
      <div className="employee-info-avatar">
        <AvatarIcon shape="circle" size={avatarSize} source={img} />
        {country && (
          <IconButton
            sx={{ position: "absolute", right: "0px", bottom: "-2px", width: "17px", height: "17px", padding: "0px" }}
          >
            {country === "Peru" ? <PeruIcon /> : <PakistanIcon />}
          </IconButton>
        )}
      </div>

      {(name || jobTitle) && (
        <div>
          {name && (
            <Tooltip title={name}>
              <div className="employee-info-name heading-h4-semibold">{name}</div>
            </Tooltip>
          )}

          {jobTitle && <div className="employee-info-job-title heading-h5-medium mb-05">{jobTitle}</div>}

          {hiredDate && (
            <div>
              <span className="text-headings-secondary para-body-s-regular">Hired on</span>
              &nbsp;
              <span className="employee-info-date para-body-s-medium">{hiredDate}</span>
            </div>
          )}
          {managedBy && (
            <div>
              <span className="text-headings-secondary para-body-s-regular">Managed by</span>
              &nbsp;
              <span className="employee-info-managed-by para-body-s-medium">{managedBy}</span>
            </div>
          )}
        </div>
      )}
    </Stack>
  );
}

export default EmployeeInfoTop;
