import { useRef, useState, useEffect } from "react";

// material ui
import { Typography, Grid, Chip, Box, Paper, Button, Grow, TextField, InputAdornment } from "@mui/material";
// widgets
import AvatarIcon from "../../../Icons/AvatarIcon";
import ProfleWithIcon from "../../../Widgets/ProfileWithIcon";

// icon
import SearchIcon from "@mui/icons-material/Search";
import totalEmployeesIcon from "../../../Icons/TotalEmployeesIcon.png";
import FilterListIcon from "@mui/icons-material/FilterList";
// widgets
import DataTable from "../../../Widgets/DataTable";
// redux dispatch
import { useSelector, useDispatch } from "react-redux";
// redux action
import { GetEmployeeList, ResetGetEmployeeList } from "../../../../store/actions/GetEmployeeList";
// router
import { useNavigate, useLocation } from "react-router-dom";
// redux-actions
import { GetEmployeeDetails } from "../../../../store/actions/GetEmployeeDetails";
import { ResetAddEmployee } from "../../../../store/actions/AddEmployee";
import { baseUrl } from "../../../../util/APIBaseUrl";
import { ResetEducationInfo } from "../../../../store/actions/Workforce/EmployeeDetils/EducationInfo";
import { ResetCalculateEmployeeSalary } from "../../../../store/actions/Workforce/AddEmployee/CalculateEmployeeSalary";
import { ResetAddEmployeeSalaryData } from "../../../../store/actions/Workforce/AddEmployee/AddEmployeeSalaryData";
import { GetCustomersList, ResetGetCustomersList } from "../../../../store/actions/Customers/GetCustomerList";
import { DeleteUser, ResetDeleteUser } from "../../../../store/actions/Settings/DeleteUser";
// widgets
import Filter from "../../../Widgets/Filter";
import EditEmployee from "../../../Widgets/Edit";
import DeleteEmployee from "../../../Widgets/Delete";
import { convertDate, getValue } from "../../CommonUtils";
import { ResetAddEmployeeSalary } from "../../../../store/actions/Workforce/AddEmployee/AddEmployeeSalary";
import { ResetemployeeSalaryInfo } from "../../../../store/actions/Workforce/EmployeeDetils/SalaryInfo";
import FileUpload from "../../../Widgets/FileUpload";
import getRequest from "../../../../util/APIHelperGet";
import PageLoader from "../../../common/PageLoader";

// constants
let filterRemoved = false;

const styles = {
  position: "absolute",
  width: 300,
  top: 35,
  right: 0,
  left: -275,
  zIndex: 1,
  border: "1px light gray",
  p: 3,
  bgcolor: "background.paper",
};

const tableheadCells = [
  {
    id: "Profile_Picture",
    sort: false,
    mobileView: true,
    label: "",
    align: "right",
    width: "10px",
    disablePadding: false,
  },
  {
    id: "First_Name",
    numeric: false,
    wrapCell: true,
    width: "230px",
    disablePadding: false,
    label: <Typography variant="h5">Employee Name</Typography>,
  },
  {
    id: "Email",
    numeric: false,
    width: "250px",
    wrapCell: true,
    disablePadding: false,
    label: <Typography variant="h5">Work Email</Typography>,
  },
  {
    id: "Company",
    numeric: false,
    disablePadding: false,
    sort: false,
    label: <Typography variant="h5">Company</Typography>,
  },
  {
    id: "Country",
    numeric: false,
    disablePadding: false,
    label: <Typography variant="h5">Country</Typography>,
  },
  {
    id: "Status",
    numeric: false,
    disablePadding: false,
    label: <Typography variant="h5">Status</Typography>,
  },
  {
    id: "Start_Date",
    numeric: false,
    width: "145px",
    disablePadding: false,
    label: <Typography variant="h5">Assigned On</Typography>,
  },
  {
    id: "action",
    label: <Typography variant="h5">Actions</Typography>,
    sort: false,
    align: "center",
    mobileView: false,
  },
];

function AdminWorkforce() {
  // constants
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const openFilterMenu = useRef(null);

  const searchParams = new URLSearchParams(location.search);
  const statusFromUrl = searchParams.get("status");

  // component states
  const [open, setOpen] = useState(false);

  const [tableRows, setTableRows] = useState([]);

  const [selectedRowID, setSelectedRowID] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [resetPage, setResetPage] = useState(false);
  const [page, setPage] = useState(0);
  const [DropdownData_, setDropdownData_] = useState({});
  const [apiFilters, setApiFilters] = useState({});
  const [sortBy, setSortBy] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({});
  const [navigateToPage, setNavigateToPage] = useState(null);
  const [searchEmployee, setSearchEmployee] = useState("");
  const [searchCustomerTimer, setSearchCustomerTimer] = useState(null);

  const rotate = open ? "rotate(180deg)" : "rotate(0)";

  // redux state
  const employeesList = useSelector((state) => state.GetEmployeeList.employeesList);
  const loadingGetEmployeesList = useSelector((state) => state.GetEmployeeList.loadingGetEmployeesList);

  const getEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.getEmployeeDetails);
  const loadingGetEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.loadingGET_EMPLOYEE_Details);

  const deleteUser = useSelector((state) => state.DeleteUser.deleteUser);
  const loadingDeleteUser = useSelector((state) => state.DeleteUser.loadingDeleteUser);

  const getCustomerList = useSelector((state) => state.GetCustomerList.getCustomerList);

  // functions
  const closeOpenMenus = (e) => {
    if (
      openFilterMenu.current &&
      open &&
      !openFilterMenu.current.contains(e.target) &&
      !document.getElementsByClassName("MuiAutocomplete-popper").length
    ) {
      setOpen(false);
    }
  };
  document.addEventListener("mousedown", closeOpenMenus);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleDelete = (event, key, option) => {
    event.preventDefault();
    setPage(() => 0);
    setOpen(false);
    if (selectedOptions[key]) {
      const newdict = { ...selectedOptions };
      newdict[key] = selectedOptions[key].filter((selectedOption) => selectedOption !== option);
      filterRemoved = true;

      if (!newdict[key].length && key == "customers") {
        dispatch(ResetGetCustomersList({}));
      }
      setSelectedOptions(newdict);
    }
  };

  const callbackFromMultiSelect = (option, key) => {
    selectedOptions[key] = option;
    setResetPage(true);
    setPage(() => 0);
    setSelectedOptions({
      ...selectedOptions,
    });

    if (!Object.keys(option).length && key == "customers") {
      dispatch(ResetGetCustomersList({}));
    }
  };

  const handleRowsPerPage = (pagination) => {
    setRowsPerPage(pagination.rowsPerPage);
    setPage(pagination.page);
  };

  const handleSelectedRow = (selectedEmployeeID, navigateTo) => {
    let loggedInUser = {};
    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    dispatch(ResetAddEmployeeSalaryData({}));
    dispatch(ResetAddEmployeeSalary({}));
    dispatch(ResetCalculateEmployeeSalary({}));
    if (Object.keys(loggedInUser).length && loggedInUser.userId != undefined && selectedEmployeeID) {
      dispatch(
        GetEmployeeDetails({
          customerID: loggedInUser?.userId,
          employeeID: selectedEmployeeID,
          request: `employee/${selectedEmployeeID}`,
        })
      );

      setSelectedRowID(selectedEmployeeID);
      setNavigateToPage(navigateTo);
    }
  };

  const handleDeleteRow = (selectedEmployeeId) => {
    let loggedInUser = {};
    let deleteUserRequest = "";
    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if (selectedEmployeeId != undefined && selectedEmployeeId != null) {
      if (loggedInUser?.type == "edge") {
        deleteUserRequest = `employee/${selectedEmployeeId}`;
      }
      dispatch(
        DeleteUser({
          request: deleteUserRequest,
        })
      );
    }
  };

  const handleRequestSortBy = (request) => {
    setSortBy(request);
    dispatch(
      GetEmployeeList({
        rowsPerPage,
        searchEmployee,
        page,
        sortBy: {
          field: request.orderBy,
          direction: request.order,
        },
        dist: apiFilters,
      })
    );
  };

  const searchCustomer = (customerName) => {
    clearTimeout(searchCustomerTimer);
    const newTimer = setTimeout(() => {
      if (customerName.length >= 3) {
        dispatch(
          GetCustomersList({
            customerName,
            loaderFlag: false,
          })
        );
      } else {
        dispatch(ResetGetCustomersList({}));
      }
    }, 500);

    setSearchCustomerTimer(newTimer);
  };

  const handleReset = () => {
    setOpen(false);
    setSelectedOptions({ countries: [], customers: [], status: [] });
    setResetPage(true);
    setPage(0);
    setSearchEmployee("");
    filterRemoved = true;
    dispatch(ResetGetCustomersList({}));
  };

  const fetchNewEmpList = () => {
    dispatch(
      GetEmployeeList({
        rowsPerPage: 10,
        page: 0,
        dist: {},
        sortBy: {},
      })
    );
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleSearchEmployee = (event) => {
    setSearchEmployee(event.target.value);
    setResetPage(true);
    setApiFilters({
      ...apiFilters,
      name: event.target.value,
    });
    setPage(0);
  };

  const handleExport = async () => {
    const name = searchEmployee || "";
    const response = await getRequest(
      `${baseUrl}/employees/export?${new URLSearchParams({
        limit: rowsPerPage,
        export: true,
        page,
        orderBy: JSON.stringify({
          field: sortBy?.field,
          direction: sortBy?.direction,
        }),
        filter: JSON.stringify({
          name: [name],
          country: apiFilters?.countries,
          company: apiFilters?.customers,
          status: apiFilters?.status,
        }),
      })}`,
      true,
      "export"
    );

    if (!response?.ok) {
      return;
    }
    const url = window.URL.createObjectURL(response?.payload);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = url;

    link.download = "Employees";
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
  };

  // methods

  useEffect(() => {
    let apiFilter = {};
    let arr = [];

    if (Object.keys(selectedOptions).length > 0) {
      arr = [];
      Object.keys(selectedOptions).forEach((header) => {
        selectedOptions[header].map((option) => arr.push(option.key));

        apiFilter[header] = arr;
        arr = [];
      });
      setApiFilters(apiFilter);

      dispatch(
        GetEmployeeList({
          rowsPerPage,
          searchEmployee,
          // page: 0,
          page,
          dist: apiFilter,
          sortBy: {
            field: sortBy.orderBy,
            direction: sortBy.order,
          },
        })
      );
      apiFilter = [];
    } else if (filterRemoved) {
      dispatch(
        GetEmployeeList({
          rowsPerPage,
          searchEmployee,
          page,
          dist: null,
          sortBy: {
            field: sortBy.orderBy,
            direction: sortBy.order,
          },
        })
      );
    }
  }, [selectedOptions, rowsPerPage, page, searchEmployee]);

  useEffect(() => {
    if (getEmployeeDetails.ok) {
      if (navigateToPage != null) {
        if (selectedRowID != null && navigateToPage == "details") {
          navigate(`/main/details/${selectedRowID}`);
        } else if (navigateToPage == "addEmployee") {
          navigate("/main/addEmployee", {
            state: {
              navigatedFor: "editEmployee",
              redirectTo: "workforce",
              editEmployeeId: selectedRowID,
            },
          });
        }
      }
    }
  }, [getEmployeeDetails]);

  useEffect(() => {
    const Data = [];

    if (employeesList != undefined && Object.keys(employeesList).length) {
      employeesList?.rows?.map((employee) =>
        Data.push({
          id: employee.employeeId,
          Profile_Picture: (
            <Box display="flex" alignItems="flex-end" justifyContent="flex-end" ml={0} pl={0} sx={{ ml: 0, pl: 0 }}>
              <AvatarIcon
                shape="circle"
                size={{
                  width: "8vh",
                  height: "8vh",
                  borderRadius: "50%",
                }}
                source={`${baseUrl}/employee/${employee?.employeeId}/logo?id=${employee?.pictureVersion}&variant=thumbnail`}
              />
            </Box>
          ),
          First_Name: (
            <ProfleWithIcon
              type="textOnly"
              id="name"
              data={{
                name: `${getValue(employee.firstName)} ${getValue(employee.lastName)}`,
                role: getValue(employee.jobTitle),
              }}
            />
          ),
          Email: (
            <Typography id="email" variant="h3">
              {getValue(employee.email)}
            </Typography>
          ),
          Company: (
            <Typography id="company" variant="h3">
              {getValue(employee.companyName)}
            </Typography>
          ),
          Country: (
            <Typography id="country" variant="h3" pr={1}>
              {getValue(employee.country)}
            </Typography>
          ),
          Status: (
            <Button
              id="status"
              variant="contained"
              disableElevation
              sx={{
                backgroundColor:
                  // eslint-disable-next-line no-nested-ternary
                  employee?.status == "assigned"
                    ? "assigned.light"
                    : employee?.status == "unassigned"
                      ? "unAssigned.light"
                      : "pending.light",

                color:
                  // eslint-disable-next-line no-nested-ternary
                  employee?.status == "assigned"
                    ? "assigned.main"
                    : employee?.status == "unassigned"
                      ? "unAssigned.main"
                      : "pending.main",

                fontWeight: "bold",
                borderRadius: 2,
                ml: -4,
                width: "100px",
                height: "27px",

                "&:hover": {
                  backgroundColor:
                    // eslint-disable-next-line no-nested-ternary
                    employee?.status == "assigned"
                      ? "assigned.dark"
                      : employee?.status == "unassigned"
                        ? "unAssigned.dark"
                        : "pending.dark",
                },
              }}
            >
              <Typography id="status-emp" fontSize="12px" fontFamily="Poppins-Medium">
                {capitalizeFirstLetter(getValue(employee.status))}
              </Typography>
            </Button>
          ),
          Start_Date: (
            <Typography id="start-date" variant="h3">
              {convertDate(employee.startDate)}
            </Typography>
          ),
          action: (
            <>
              <EditEmployee
                selectedId={employee.employeeId}
                handleSelectedRow={handleSelectedRow}
                navigateTo="details"
              />

              <DeleteEmployee selectedId={employee.employeeId} handleDeleteRow={handleDeleteRow} title="Employee" />
            </>
          ),
        })
      );
      setTableRows(Data);
    }
  }, [employeesList]);

  useEffect(() => {
    let loggedInUser = {};

    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

    if (Object.keys(deleteUser).length && deleteUser?.ok && deleteUser?.payload) {
      if (Object.keys(loggedInUser).length && loggedInUser.userId != undefined && loggedInUser?.type == "edge") {
        dispatch(
          GetEmployeeList({
            rowsPerPage,
            searchEmployee,

            page,
            sortBy: {
              field: sortBy.orderBy,
              direction: sortBy.order,
            },
            dist: apiFilters,
          })
        );
      }
      dispatch(ResetDeleteUser({}));
    }
  }, [deleteUser]);

  useEffect(() => {
    const customers = [];

    if (getCustomerList?.ok) {
      getCustomerList?.payload?.map((data) =>
        customers.push({
          key: data.companyName,
          value: data.companyName,
        })
      );
    }
    setDropdownData_({
      ...DropdownData_,
      customers,
    });
  }, [getCustomerList]);

  useEffect(() => {
    const countries = [];
    const statusOption = [];
    const customers = [];
    let dropdownData = {};
    dropdownData = JSON.parse(sessionStorage.getItem("dropdownData"));

    dispatch(ResetDeleteUser({}));
    dispatch(ResetAddEmployee({}));
    dispatch(ResetCalculateEmployeeSalary({}));
    dispatch(ResetAddEmployeeSalaryData({}));
    dispatch(ResetAddEmployeeSalary({}));
    dispatch(ResetEducationInfo({}));

    if (dropdownData != undefined && Object.keys(dropdownData).length && dropdownData.ok) {
      dropdownData?.payload?.country?.map((data) =>
        countries.push({
          key: data.countryCode,
          value: data.countryCode,
        })
      );

      dropdownData?.payload?.employeeStatus?.map((data) =>
        statusOption.push({
          key: data.value,
          value: data.label,
        })
      );
    }
    const statusOptionsFromUrl =
      statusFromUrl === "active"
        ? statusOption.filter((item) => item.key !== "inactive")
        : statusOption.filter((item) => item.key === statusFromUrl);

    setSelectedOptions({
      status: statusFromUrl ? statusOptionsFromUrl : statusOption.filter((item) => item.key !== "inactive"),
    });

    setDropdownData_({
      ...DropdownData_,
      status: statusOption,
      customers,
      countries,
    });
    return () => {
      dispatch(ResetGetEmployeeList({}));
    };
  }, []);

  return (
    <>
      {loadingDeleteUser == true || loadingGetEmployeeDetails == true || loadingGetEmployeesList == true ? (
        <PageLoader />
      ) : null}
      <Grid container direction="column" rowSpacing={3}>
        <FileUpload onUploadSuccess={fetchNewEmpList} />
        <Grid item>
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-end"
            columnSpacing={3}
            style={{
              left: "-80px",
              marginTop: "-10px",
            }}
          >
            <Grid item>
              {Object.keys(selectedOptions).map((key) => {
                return selectedOptions[key].map((option) => {
                  return (
                    <Chip
                      key={option.key}
                      label={<Typography variant="h6">{option.value}</Typography>}
                      clickable
                      sx={{
                        bgcolor: "#D3E0F4",
                        mx: 0.5,
                        height: 26,
                        borderRadius: "10px",
                        color: "#1161D5",
                        "& .MuiChip-deleteIcon": {
                          color: "#3C7DDC",
                          fontSize: "1.0rem",
                        },
                      }}
                      onDelete={(e) => handleDelete(e, key, option)}
                    />
                  );
                });
              })}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <DataTable
            rowsData={tableRows}
            resetPage={resetPage}
            selectedOptions={apiFilters}
            totalCount={employeesList?.count ? employeesList.count : 0}
            tableheadCells={tableheadCells}
            title={`Your Employees (${employeesList?.count ? employeesList.count : 0})`}
            titleIcon={totalEmployeesIcon}
            subTitle={null}
            toolBar
            slectedRowNavigation="/main/details"
            sortByName="First_Name"
            openDetailsPage="First_Name"
            handleRowsPerPage={handleRowsPerPage}
            handleSelectedRow={handleSelectedRow}
            handleRequestSortBy={handleRequestSortBy}
            exportButton={
              <Button
                disableElevation
                variant="contained"
                fullWidth
                sx={{
                  width: "140px",
                  height: "35px",
                  borderRadius: "5px",
                  color: "white",
                }}
                onClick={handleExport}
              >
                <Typography id="export-emps" variant="h6" color="white">
                  Export
                </Typography>
              </Button>
            }
            filterButton={
              <Box id="filter-emps" sx={{ position: "relative" }} ref={openFilterMenu}>
                <FilterListIcon
                  onClick={handleClick}
                  style={{
                    transform: rotate,
                    transition: "all 0.2s linear",
                  }}
                />
                <Grow
                  in={open}
                  style={{
                    transformOrigin: "right 0 0 ",
                  }}
                  {...(open ? { timeout: 350 } : {})}
                  unmountOnExit
                >
                  <Paper elevation={3} sx={styles}>
                    <Filter
                      searchCustomer={searchCustomer}
                      data={DropdownData_}
                      selectedOptions={selectedOptions}
                      handleReset={handleReset}
                      callbackFromMultiSelect={callbackFromMultiSelect}
                    />
                  </Paper>
                </Grow>
              </Box>
            }
            SearchEmployee={
              <TextField
                id="search-emp"
                sx={{
                  "&.MuiTextField-root": {
                    marginTop: 0,
                  },
                  "& .MuiInputBase-root": {
                    backgroundColor: "white",
                    borderRadius: "5px",
                    fontFamily: "Poppins-Medium !important",
                    height: "38px",
                  },
                  "& .MuiInputBase-input": {},
                }}
                variant="outlined"
                placeholder="Enter Employee Name"
                size="small"
                name="email"
                value={searchEmployee}
                fullWidth
                onChange={handleSearchEmployee}
                margin="normal"
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon
                        sx={{
                          mx: 1,
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            }
            AddNewRowButton={
              <Button
                disableElevation
                variant="contained"
                id="addEmployee"
                key="addEmployee"
                fullWidth
                sx={{
                  width: "140px",
                  height: "35px",
                  borderRadius: "5px",
                  color: "white",
                }}
                onClick={() => {
                  dispatch(ResetAddEmployeeSalaryData({}));
                  dispatch(ResetAddEmployeeSalary({}));
                  dispatch(ResetemployeeSalaryInfo({}));
                  navigate("/main/addEmployee", {
                    state: {
                      navigatedFor: "addEmployee",
                      redirectTo: "workforce",
                    },
                  });
                }}
              >
                <Typography variant="h6" color="white">
                  Add Employee
                </Typography>
              </Button>
            }
          />
        </Grid>
      </Grid>
    </>
  );
}

export default AdminWorkforce;
