import React from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import Button from "components/common/Button";

function EmployeeAssignmentBanner() {
  return (
    <div className="pt-175 px-2">
      <div className="assignment-banner-bg flex items-center justify-between p-075">
        <div className="flex items-center space-x-025">
          <InfoOutlinedIcon className="info-icon" />
          <p className="para-body-m-regular">
            This employee has not yet been assigned to a customer. Please assign them now to begin customer billing.
          </p>
        </div>
        <Button label="Assign to Customer" variant="outlined" color="warning" size="medium" onClick={() => {}} />
      </div>
    </div>
  );
}

export default EmployeeAssignmentBanner;
