import { Modal, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiModal-backdrop": {
      backgroundColor: "background.paper",
    },
  },
  modalContent: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: "12px",
    padding: "24px",
  },
}));

function CustomModal({ open, children, title, width = "24rem", sx, onClose }) {
  const classes = useStyles();
  return (
    <Modal open={open} className={classes.modalContainer} onClose={onClose}>
      <Box className={classes.modalContent} sx={{ width, ...sx }}>
        <p className="heading-h2-semibold">{title}</p>
        {children}
      </Box>
    </Modal>
  );
}

export default CustomModal;
