import React from "react";

import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";

import "./info-section.scss";

function InfoSection({ position, header, label, secondaryBtn, primarybtn, editIcon, onEdit, children }) {
  const borderRadiusClass =
    {
      single: "border-radius-single",
      top: "border-radius-top",
      middle: "border-radius-middle",
      bottom: "border-radius-bottom",
    }[position] || "border-radius-single";

  return (
    <div className={`info-container ${borderRadiusClass}`} data-testid="info-container">
      <div className="header-section flex justify-between items-center">
        <p className="color-text-headings-primary heading-h4-semibold">{header}</p>
        <div className="flex items-start space-x-3">
          {secondaryBtn && (
            <button type="button" className="info-secondary-button button-small">
              Button
            </button>
          )}
          {editIcon && (
            <div onClick={onEdit && onEdit}>
              <ModeEditOutlinedIcon className="info-edit-icon" />
            </div>
          )}
          {primarybtn && (
            <button type="button" className="info-primary-button button-small">
              Button
            </button>
          )}
          {label && <p className="info-label-text para-body-s-regular">Text</p>}
        </div>
      </div>
      {children}
    </div>
  );
}

export default InfoSection;
